/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import MenuTab from './components/MenuTab';
import AccountTab from './components/accountTab';
import Company from 'types/company';
import { Product, Keywords, Basket } from 'types/product';
import axios from 'axios';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Carousel from '@components/Carousel';
import SyncLoader from 'react-spinners/SyncLoader';
import { useDispatch, useSelector } from 'react-redux';
import { setNavMenu } from 'src/redux/navmenuSlice';
import { setCardCode, setCredits, setAccountDetails } from 'src/redux/userSlice';
import { getBasket, mergeGuestItemsToBasket } from 'src/redux/basketSlice';
import { selectIsNeedMoreClicked, setIsNeedMoreClicked } from 'src/redux/productConfigSlice';
import { RootState } from 'src/redux/store';
import getCookies from '@utils/getCookies';
import dynamic from 'next/dynamic';
// import HeaderAlert from './components/HeaderAlert';
import { useMediaQuery } from 'react-responsive';
import slugify from '@utils/slugify';

import HeaderBanner from './components/HeaderBanner';
import SignInModal from '@components/TradeAccount/components/ID/login';

const InfiniteLoader = dynamic(() => import('@components/infiniteLoader'));
const HeaderCRONav = dynamic(() => import('./components/HeaderCRONav'));
const Brand50View = dynamic(() => import('./components/HeaderCRONav/Brand50View'));

interface footerProps {
  companyID: Company;
  baseUrl?: string;
  apiKey?: string;
  userHasCookies?: boolean;
}

const NavHeader = ({ companyID, baseUrl, apiKey }: footerProps): JSX.Element => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [scrollTop, setScrollTop] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isAccountTabOpen, setIsAccountTabOpen] = useState<boolean>(false);
  const [isProductsMenuOpen, setIsProductsMenuOpen] = useState<boolean>(false);
  const [isBrandsMenuOpen, setIsBrandsMenuOpen] = useState<boolean>(false);
  const [isProductStage2Open, setIsProductStage2Open] = useState<boolean>(false);
  const [isProductStage3Open, setIsProductStage3Open] = useState<boolean>(false);
  const [isCROMenuOpen, setIsCROMenuOpen] = useState<boolean>(false);
  const [isTopRatedBrandsOpen, setIsTopRatedBrandsOpen] = useState<boolean>(false);
  const [isView50Brands, setIsView50Brands] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [activeLink, setActiveLink] = useState({
    name: '',
    link: '',
    children: {
      name: '',
      link: '',
    },
  });
  const [accountTab, setAccountTab] = useState<number>(0);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');
  // const [searchStringOnHover, setSearchStringOnHover] = useState<string>('');
  const [searchResultKeywords, setSearchResultKeywords] = useState<Array<Keywords> | []>([]);
  const [searchResultProducts, setSearchResultProducts] = useState<Product[] | []>([]);
  const [isFetchingSearch, setIsFetchingSearch] = useState<boolean>(false);
  const [isAccountDetailsLoading, setIsAccountDetailsLoading] = useState<boolean>(false);
  const [activeKeyword, setActiveKeyword] = useState<number>(0);
  const navMenu = useSelector((state: RootState) => state.navMenu);
  const cardCode = useSelector((state: RootState) => state.user.cardCode);
  const userCredits = useSelector((state: RootState) => state.user.credits);
  const accountDetails = useSelector((state: RootState) => state.user.details);
  const basketItems = useSelector((state: RootState) => state.basket.basketItems);
  const basketId = useSelector((state: RootState) => state.basket.basketId);
  const basketTotal = useSelector((state: RootState) => state.basket.basketTotal);
  const basketLoading = useSelector((state: RootState) => state.basket.isLoading);
  const [userHasCookies, setUserHasCookies] = useState<boolean>(false);
  const isNeedMoreClicked: boolean = useSelector(selectIsNeedMoreClicked);
  // let cursorEnter: any;
  const [guestUserBasket, setGuestUserBasket] = useState<string>('');
  const [showNavBar, setShowNavBar] = useState<boolean>(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState<boolean>(false);

  const isHomepage = router.asPath === '/';

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const popularSearches = [
    {
      query: 'door handles',
      fireIcon: true,
    },
    {
      query: 'hinges',
      fireIcon: false,
    },
    {
      query: 'brass',
      fireIcon: true,
    },
    {
      query: 'beehive door knobs',
      fireIcon: true,
    },
    {
      query: 'bathroom lock',
      fireIcon: false,
    },
  ];

  useEffect(() => {
    const cookieAuthToken = getCookies('authToken');
    const cookieAuthEmail = getCookies('authEmail');
    const modalAction = router.query.action as string;
    if (modalAction === 'register' && !cookieAuthToken && !cookieAuthEmail) {
      setIsRegisterModalOpen(true);
    }
  }, [router]);

  useEffect(() => {
    if (cardCode) {
      if (!cardCode.toString().startsWith('C')) {
        setTimeout(() => {
          if (getCookies('authToken') !== '' && getCookies('authEmail') !== '') {
            dispatch(
              setCardCode({
                companyID,
                baseUrl,
                apiKey,
                token: getCookies('authToken'),
              })
            );
          }
        }, 75000);
      }
    }
  }, [cardCode]);

  // CRO Experiment Search Bar Filters
  const [isSearchBarFilterOpen, setIsSearchBarFilterOpen] = useState<boolean>(false);
  const [searchCategoryFilter, setSearchCategoryFilter] = useState<{
    label: string;
    url: string;
  }>({
    label: 'All',
    url: 'all',
  });

  useEffect(() => {
    if (isHomepage) {
      if (isMobile) {
        window.addEventListener('scroll', () => {
          if (window.pageYOffset > 150) {
            setShowNavBar(true);
          } else {
            setShowNavBar(false);
          }
        });
      }
      return () => {
        window.removeEventListener('scroll', null);
      };
    }
  }, [isHomepage, isMobile]);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  // function handleOnMouseEnter(index: number, keywordContent: string): void {
  //   cursorEnter = setTimeout(() => {
  //     setActiveKeyword(index);
  //     setSearchStringOnHover(keywordContent);
  //   }, 500);
  // }

  const keywordMatchHelper = (keyword: string, resultKeyword: string): string => {
    const arrayKeyword = resultKeyword.replace(
      keyword,
      `<mark class="text-white underline bg-transparent">${keyword}</mark>`
    );
    return arrayKeyword;
  };

  const handleClickReset = (): void => {
    setIsMenuOpen(false);
    setIsProductsMenuOpen(false);
    setIsBrandsMenuOpen(false);
    setIsProductStage2Open(false);
    setIsProductStage3Open(false);
    setIsAccountTabOpen(false);
    setIsSearchOpen(false);
    setIsCROMenuOpen(false);
    setIsTopRatedBrandsOpen(false);
    setIsView50Brands(false);
    setAccountTab(0);
    setSearchString('');
    setActiveLink({
      name: '',
      link: '',
      children: {
        name: '',
        link: '',
      },
    });
    dispatch(setIsNeedMoreClicked(false));
    document.body.style.overflow = 'auto';
  };

  const handleClickSearch = (): void => {
    const keyword = encodeURIComponent(searchString);
    const isSearchAll = searchCategoryFilter.url === 'all';
    const isSearchFireClearance =
      searchCategoryFilter.url === 'clearancerange' || searchCategoryFilter.url === 'firerated';
    const altUrlSearchFilter =
      isSearchFireClearance && searchCategoryFilter.url !== 'new'
        ? `/search?query=${searchCategoryFilter.url}%20${keyword}`
        : `/browse/${searchCategoryFilter.url}/filter/%24s%3D${keyword}`;
    router.push(isSearchAll ? `/search?query=${keyword}` : altUrlSearchFilter);
    setIsSearchOpen(false);
    setSearchString('');
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    if (getCookies('IDLWebUser') !== '') {
      setUserHasCookies(true);
    }
    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        setIsSearchOpen(false);
        document.body.style.overflow = 'auto';
      }
    });
  }, []);

  const formatBasket = (basketItems) => {
    const final = basketItems.map((item) => {
      return {
        price: item.prices.price,
        quantity: item.quantity,
        item: item.skuCode,
      };
    });
    return final;
  };

  useEffect(() => {
    const cartValueElement = document.getElementById('dy-cart-value');
    if (cartValueElement) {
      cartValueElement.click();
    }
  }, [basketTotal]);

  useEffect(() => {
    const localStorageAuthToken = getCookies('authToken');
    const localStorageAuthEmail = getCookies('authEmail');
    setGuestUserBasket(localStorage.getItem('guestBasket'));
    // window.addEventListener('storage', () => {
    //   setGuestUserBasket(localStorage.getItem('guestBasket'));
    // });
    if (localStorageAuthToken && localStorageAuthEmail) {
      const finalBasket = formatBasket(basketItems);
      if (finalBasket.length !== 0) {
        window.ScarabQueue?.push(['cart', finalBasket]);
        window.ScarabQueue?.push(['go']);
      }
    }
  }, [basketItems]);

  useEffect(() => {
    if (searchString !== '') {
      setIsFetchingSearch(true);
      setSearchResultKeywords([]);
      setSearchResultProducts([]);
      // const timeOutFetch = setTimeout(() => {
      axios({
        url: `${baseUrl}/product/Suggest?query=${searchString}&Referer=${
          companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
        }&companyID=${companyID}`,
        headers: {
          // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
          'ocp-apim-subscription-key': apiKey,
        },
      })
        .then((response) => {
          setSearchResultKeywords(response.data.view.suggestionGroups.keywords);
          setSearchResultProducts(response.data.view.suggestionGroups.products);
          setActiveKeyword(0);
          setIsFetchingSearch(false);
        })
        .catch(() => {
          setSearchResultKeywords((prevState) => prevState);
          setSearchResultProducts((prevState) => prevState);
          setActiveKeyword(0);
          setIsFetchingSearch(false);
        });
      // }, 1500);
      // return () => clearTimeout(timeOutFetch);
    } else {
      setIsFetchingSearch(false);
      setSearchResultKeywords([]);
      setSearchResultProducts([]);
    }
    return () => undefined;
  }, [searchString, companyID, apiKey, baseUrl]);

  // useEffect(() => {
  //   if (searchStringOnHover !== '') {
  //     setIsFetchingSearch(true);
  //     const timeOutFetch = setTimeout(() => {
  //       axios({
  //         url: `${baseUrl}/product/Suggest?query=${searchStringOnHover}&Referer=${
  //           companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
  //         }&companyID=${companyID}`,
  //         headers: {
  //           // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
  //           'ocp-apim-subscription-key': apiKey,
  //         },
  //       })
  //         .then((response) => {
  //           setSearchResultKeywords(response.data.view.suggestionGroups.keywords);
  //           setSearchResultProducts(response.data.view.suggestionGroups.products);
  //           setIsFetchingSearch(false);
  //         })
  //         .catch(() => {
  //           setSearchResultKeywords((prevState) => prevState);
  //           setSearchResultProducts((prevState) => prevState);
  //           setIsFetchingSearch(false);
  //         });
  //     }, 1500);
  //     return () => clearTimeout(timeOutFetch);
  //   }
  //   return () => undefined;
  // }, [searchStringOnHover, companyID, apiKey, baseUrl]);

  useEffect(() => {
    dispatch(setNavMenu({ baseUrl, apiKey }));
    if (getCookies('authToken') !== '' && getCookies('authEmail') !== '') {
      dispatch(
        setCardCode({
          companyID,
          baseUrl,
          apiKey,
          token: getCookies('authToken'),
        })
      );
    }
  }, [companyID, baseUrl, apiKey, dispatch]);

  useEffect(() => {
    if (cardCode !== '') {
      setIsAccountDetailsLoading(true);
      dispatch(setCredits({ companyID, baseUrl, apiKey, cardCode }));
    } else {
      setIsAccountDetailsLoading(false);
    }
  }, [cardCode, companyID, baseUrl, apiKey, dispatch]);

  useEffect(() => {
    const localStorageAuthToken = getCookies('authToken');
    const localStorageAuthEmail = getCookies('authEmail');
    if (cardCode !== '' && !isLoggedIn && localStorageAuthToken && localStorageAuthEmail) {
      dispatch(
        setAccountDetails({
          companyID,
          baseUrl,
          apiKey,
          email: localStorageAuthEmail,
          cardCode,
          userHasCookies,
        })
      );
      setIsLoggedIn(true);
      dispatch(getBasket({ companyID, baseUrl, apiKey, token: localStorageAuthToken }));
    }
  }, [cardCode, companyID, baseUrl, apiKey, dispatch, userCredits, isLoggedIn, userHasCookies]);

  useEffect(() => {
    const token = getCookies('authToken');
    if (guestUserBasket !== null && guestUserBasket !== '' && basketId !== 0 && cardCode !== '') {
      dispatch(
        mergeGuestItemsToBasket({
          companyID,
          baseUrl,
          apiKey,
          basketId,
          accountCode: cardCode,
          emailAddress: getCookies('authEmail'),
          guestBasketItems: JSON.parse(guestUserBasket),
          token,
        })
      );
      setGuestUserBasket('');
      localStorage.removeItem('guestBasket');
    }
  }, [cardCode, companyID, baseUrl, apiKey, dispatch, userCredits, basketId, guestUserBasket]);

  useEffect(() => {
    if (isRegisterModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isRegisterModalOpen]);

  return (
    <>
      {/* <div className={`text-white theme-id ${isHomepage ? 'h-16' : 'h-32'} md:h-20`}> */}
      {isRegisterModalOpen && (
        <SignInModal
          isRegister
          fromNav
          onClose={() => {
            setIsRegisterModalOpen(false);
          }}
        />
      )}
      <div className={`text-white theme-id ${isHomepage ? 'h-32' : 'h-32'} md:h-20`}>
        <div
          className={`fixed inset-0 h-screen w-full z-40 bg-black-backdrop cursor-default ${
            isMenuOpen ||
            isAccountTabOpen ||
            isSearchOpen ||
            isCROMenuOpen ||
            isTopRatedBrandsOpen ||
            isView50Brands ||
            isNeedMoreClicked
              ? 'block'
              : 'hidden'
          }`}
          role="button"
          tabIndex={0}
          onClick={handleClickReset}
        />
        <MenuTab
          companyID={companyID}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          isProductsMenuOpen={isProductsMenuOpen}
          setIsProductsMenuOpen={setIsProductsMenuOpen}
          isBrandsMenuOpen={isBrandsMenuOpen}
          setIsBrandsMenuOpen={setIsBrandsMenuOpen}
          isProductStage2Open={isProductStage2Open}
          setIsProductStage2Open={setIsProductStage2Open}
          isProductStage3Open={isProductStage3Open}
          setIsProductStage3Open={setIsProductStage3Open}
          brandItems={navMenu?.brandItems}
          productCategory={navMenu?.productCategory}
          activeLink={activeLink}
          setActiveLink={setActiveLink}
        />
        <AccountTab
          companyID={companyID}
          isMenuOpen={isAccountTabOpen}
          setIsMenuOpen={setIsAccountTabOpen}
          accountTab={accountTab}
          setAccountTab={setAccountTab}
          baseUrl={baseUrl}
          apiKey={apiKey}
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          setIsRegisterModalOpen={setIsRegisterModalOpen}
          userCredits={userCredits}
          accountDetails={accountDetails}
          isAccountDetailsLoading={isAccountDetailsLoading}
        />
        <header
          className={`bg-black-light fixed w-full ${
            isMenuOpen || isAccountTabOpen || isNeedMoreClicked ? 'z-20' : 'z-40'
          } transition-colors duration-250`}
        >
          {/* <HeaderAlert /> */}
          <div className="root-container">
            <div
              className={`root-inner--alt grid ${
                // isHomepage && !showNavBar ? 'h-16' : 'h-32'

                isHomepage && !showNavBar ? 'h-32' : 'h-32'
              } md:h-auto`}
            >
              <div className="root-main--alt flex flex-row items-center w-full flex-wrap h-auto md:h-20 md:flex-no-wrap relative">
                {navMenu?.productCategory?.length > 0 && navMenu?.brandItems?.brands?.length > 0 && (
                  <div
                    className={`h-full w-full hidden absolute -z-1 pt-20 ${
                      isCROMenuOpen || isView50Brands ? 'xl:block' : ''
                    }`}
                    onMouseLeave={() => {
                      handleClickReset();
                    }}
                  >
                    <div className="flex flex-row bg-white left-0 absolute w-full menu-cats">
                      <div
                        className={`border-r-2 border-solid ${
                          isView50Brands ? 'border-black-lighter' : 'border-concrete'
                        }`}
                      >
                        <div className="flex flex-col w-nav-category whitespace-no-wrap">
                          {isView50Brands ? (
                            <>
                              <p className="font-bold p-4 text-black-lighter bg-white">
                                Our Exclusive Brands
                              </p>
                              <Brand50View
                                brandItems={navMenu?.brandItems}
                                alphas={['#']}
                                title={'#'}
                                handleClickReset={handleClickReset}
                              />
                              <Brand50View
                                brandItems={navMenu?.brandItems}
                                alphas={['A', 'B', 'C']}
                                title={'A-C'}
                                handleClickReset={handleClickReset}
                              />
                              <Brand50View
                                brandItems={navMenu?.brandItems}
                                alphas={['D', 'E', 'F']}
                                title={'D-F'}
                                handleClickReset={handleClickReset}
                              />
                              <Brand50View
                                brandItems={navMenu?.brandItems}
                                alphas={['G', 'H', 'I']}
                                title={'G-I'}
                                handleClickReset={handleClickReset}
                              />
                              <Brand50View
                                brandItems={navMenu?.brandItems}
                                alphas={['J', 'K', 'L']}
                                title={'J-L'}
                                handleClickReset={handleClickReset}
                              />
                              <Brand50View
                                brandItems={navMenu?.brandItems}
                                alphas={['M', 'N', 'O']}
                                title={'M-O'}
                                handleClickReset={handleClickReset}
                              />
                              <Brand50View
                                brandItems={navMenu?.brandItems}
                                alphas={['P', 'Q', 'R']}
                                title={'P-R'}
                                handleClickReset={handleClickReset}
                              />
                              <Brand50View
                                brandItems={navMenu?.brandItems}
                                alphas={['S', 'T', 'U']}
                                title={'S-U'}
                                handleClickReset={handleClickReset}
                              />
                              <Brand50View
                                brandItems={navMenu?.brandItems}
                                alphas={['V', 'W', 'X']}
                                title={'V-X'}
                                handleClickReset={handleClickReset}
                              />
                              <Brand50View
                                brandItems={navMenu?.brandItems}
                                alphas={['Y', 'Z']}
                                title={'Y-Z'}
                                handleClickReset={handleClickReset}
                              />
                            </>
                          ) : (
                            <>
                              {navMenu?.productCategory?.map((category) => (
                                <HeaderCRONav
                                  category={category}
                                  key={category.id}
                                  handleClickReset={handleClickReset}
                                />
                              ))}
                              {companyID === Company.Ironmongery && (
                                <Link href="/search?query=firerated">
                                  <a
                                    className="nav-category__main"
                                    role="button"
                                    tabIndex={0}
                                    onClick={handleClickReset}
                                  >
                                    Fire Rated Products
                                  </a>
                                </Link>
                              )}
                              <Link href="/browse/new">
                                <a
                                  className="nav-category__main"
                                  role="button"
                                  tabIndex={0}
                                  onClick={handleClickReset}
                                >
                                  New Products
                                </a>
                              </Link>
                              <Link href="/search?query=clearancerange">
                                <a
                                  className="nav-category__main"
                                  role="button"
                                  tabIndex={0}
                                  onClick={handleClickReset}
                                >
                                  <p>Clearance</p>
                                </a>
                              </Link>
                              <Link href="/browse/all">
                                <a
                                  className="nav-category__main"
                                  role="button"
                                  tabIndex={0}
                                  onClick={handleClickReset}
                                >
                                  View All
                                </a>
                              </Link>
                            </>
                          )}
                        </div>
                      </div>
                      {/* Close Icon */}
                      <div className="transparent-cont">
                        <div className="inserted-close-container">
                          <div
                            className="inner-close-container"
                            onClick={handleClickReset}
                            role="button"
                            tabIndex={0}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="close-icon feather feather-x"
                            >
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                          </div>
                          <p>Close</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* PRIMARY NAV */}
                <nav className="order-3 flex items-center">
                  <button
                    type="button"
                    className="outline-none rounded-full py-0 no-underline transition-all duration-200 inline-flex xl:hidden relative bg-transparent text-16px font-bold leading-6 mr-4 text-center border-none hover:bg-grey-primary hover:text-orange"
                    title="Show Menu"
                    aria-label="Show Menu"
                    onClick={() => {
                      setIsMenuOpen(true);
                      document.body.style.overflow = 'hidden';
                    }}
                  >
                    <span className="px-2 h-9 w-auto relative inline-flex justify-center items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        focusable="false"
                        className="h-6 w-6 inline-block align-middle overflow-visible fill-current lg:mr-2"
                        viewBox="0 0 448 512"
                      >
                        <path d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"></path>
                      </svg>
                      <span className="hidden lg:block xl:hidden">Menu</span>
                    </span>
                  </button>

                  <div className="self-center">
                    <Link href="/">
                      <a
                        title="IronmongeryDirect"
                        role="button"
                        tabIndex={0}
                        onClick={handleClickReset}
                      >
                        <img
                          src="/images/ID-Dark-bg.svg"
                          alt="IronmongeryDirect"
                          className="h-6 w-24 block md:h-9 md:w-40 mr-10"
                        />
                      </a>
                    </Link>
                  </div>

                  <ul className="hidden lg:flex flex-row xxl:hidden">
                    <li>
                      <button
                        // href="/browse/all"
                        className="text-white block font-bold no-underline py-10px px-6 hover:underline focus:outline-none"
                        data-index="1"
                        onClick={() => {
                          setIsMenuOpen(true);
                          setIsProductsMenuOpen(true);
                          document.body.style.overflow = 'hidden';
                        }}
                      >
                        Products
                      </button>
                    </li>
                    <li className="hidden xl:block">
                      <button
                        // href="/brands"
                        className="text-white block font-bold no-underline py-10px px-6 hover:underline focus:outline-none"
                        data-index="2"
                        onClick={() => {
                          setIsMenuOpen(true);
                          setIsBrandsMenuOpen(true);
                          document.body.style.overflow = 'hidden';
                        }}
                      >
                        Brands
                      </button>
                    </li>
                  </ul>

                  <ul className="flex">
                    <li
                      className="cursor-pointer hidden xxl:block"
                      onMouseEnter={() => {
                        setIsCROMenuOpen(true);
                        setIsTopRatedBrandsOpen(false);
                        setIsView50Brands(false);
                        document.body.style.overflow = 'hidden';
                      }}
                    >
                      <div
                        id="nav-header-products"
                        className={`focus:outline-none cro-nav__btn ${
                          isCROMenuOpen ? 'active' : ''
                        }`}
                      >
                        <p className="mr-2">Products</p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                          className="transition-all duration-300 fill-current h-4 w-4"
                        >
                          <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                        </svg>
                      </div>
                    </li>

                    <li
                      className="cursor-pointer relative hidden xxl:block"
                      onMouseEnter={() => {
                        setIsTopRatedBrandsOpen(true);
                        if (isView50Brands) {
                          setIsView50Brands(!isView50Brands);
                          setIsTopRatedBrandsOpen(false);
                        } else {
                          setIsTopRatedBrandsOpen(!isTopRatedBrandsOpen);
                          setIsView50Brands(false);
                        }
                        setIsCROMenuOpen(false);
                        document.body.style.overflow = 'hidden';
                      }}
                      onMouseLeave={() => {
                        setIsTopRatedBrandsOpen(false);
                        if (!isView50Brands) document.body.style.overflow = 'auto';
                      }}
                    >
                      <div
                        id="nav-header-top-brands"
                        className={`cro-nav__btn ${
                          isView50Brands || isTopRatedBrandsOpen ? 'active' : ''
                        }`}
                      >
                        <p className="mr-2">Top Rated Brands</p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                          className="rotate-90 transition-all duration-300 fill-current h-4 w-4"
                        >
                          <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                        </svg>
                      </div>
                      <div
                        className={`absolute left-0 flex-col w-full pt-4.5 ${
                          isTopRatedBrandsOpen ? 'flex' : 'hidden'
                        }`}
                      >
                        {navMenu?.brandItems?.popularBrands?.map((topBrand, indexTopBrands) => {
                          const url = topBrand.brandName
                            .replace(/ & /g, '202620')
                            .replace(/ /g, '');
                          return (
                            <Link
                              href={`/brands/${url.toLowerCase()}`}
                              key={`${topBrand.BrandName}-${indexTopBrands}`}
                            >
                              <a
                                id={`nav-brand-${slugify(topBrand.brandName)}`}
                                className="py-2 px-4 text-left text-black-lighter bg-white hover:font-bold hover:underline"
                                role="button"
                                tabIndex={0}
                                onClick={handleClickReset}
                              >
                                {topBrand.brandName}
                              </a>
                            </Link>
                          );
                        })}
                        <button
                          id="nav-brands-view-all"
                          onClick={() => {
                            setIsTopRatedBrandsOpen(false);
                            setIsCROMenuOpen(false);
                            setIsView50Brands(true);
                          }}
                          className="py-2 px-4 text-left text-black-lighter bg-white hover:font-bold hover:underline"
                        >
                          View all 50+ brands
                        </button>
                      </div>
                    </li>
                  </ul>
                </nav>

                {/* SEARCH BAR */}
                <div
                  className={`ml-0 max-h-full min-w-full mb-0 p-0 md:mb-0 order-7 md:flex-grow md:order-4 md:min-w-0 md:ml-8 ${
                    isSearchOpen
                      ? 'z-50 block'
                      : // : `z-10 ${isHomepage && !showNavBar ? 'hidden md:block' : ''}`
                        `z-10`
                  } transition-opacity duration-250 `}
                  id="ReactSearchInput"
                >
                  <div className={isSearchOpen ? 'search-box__container' : ''}>
                    <div className="search-box__wrapper">
                      <div className={`head__main ${isSearchOpen ? 'block' : 'hidden'}`}>
                        <button
                          className={`head__btn ${
                            companyID === Company.Ironmongery
                              ? 'hover:text-orange'
                              : 'hover:text-green'
                          }`}
                          title="Close Search"
                          aria-label="Close Search"
                          type="button"
                          id="search-btn-close"
                          onClick={() => {
                            setIsSearchOpen(false);
                            document.body.style.overflow = 'auto';
                          }}
                        >
                          <span className="head__btn--span">
                            <svg
                              focusable="false"
                              className="head__btn--svg"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                            >
                              <path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path>
                            </svg>
                            <span className="hidden xl:block">Close</span>
                          </span>
                        </button>
                      </div>
                      <div className="search-box__results">
                        <div
                          className={`flex relative flex-auto w-auto ${
                            isSearchOpen ? 'mb-6' : 'mb-0'
                          }`}
                        >
                          <div
                            className="search-dropdown"
                            onMouseLeave={() => setIsSearchBarFilterOpen(false)}
                          >
                            <div
                              className="dropdown__selection"
                              onClick={() => setIsSearchBarFilterOpen((prevState) => !prevState)}
                              role="button"
                              tabIndex={0}
                            >
                              <p>{searchCategoryFilter.label}</p>
                              <svg
                                width="12"
                                height="8"
                                viewBox="0 0 12 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M12 0.5H0L6 7.5L12 0.5Z" fill="#EFA847" />
                              </svg>
                            </div>
                            {isSearchBarFilterOpen && (
                              <ul className={`dropdown__options`}>
                                <li
                                  className={`mb-4 last:mb-0 ${
                                    searchCategoryFilter.url === 'all'
                                      ? 'text-orange underline'
                                      : ''
                                  }`}
                                  data-id={'all'}
                                  key={'all'}
                                  onClick={() => {
                                    setSearchCategoryFilter({
                                      label: 'All',
                                      url: 'all',
                                    });
                                    setIsSearchBarFilterOpen(false);
                                  }}
                                >
                                  All
                                </li>
                                <li
                                  className={`mb-4 last:mb-0 ${
                                    searchCategoryFilter.url === 'clearancerange'
                                      ? 'text-orange underline'
                                      : ''
                                  }`}
                                  data-id={'clearancerange'}
                                  key={'clearancerange'}
                                  onClick={() => {
                                    setSearchCategoryFilter({
                                      label: 'Clearance',
                                      url: 'clearancerange',
                                    });
                                    setIsSearchBarFilterOpen(false);
                                  }}
                                >
                                  Clearance
                                </li>
                                <li
                                  className={`mb-4 last:mb-0 ${
                                    searchCategoryFilter.url === 'new'
                                      ? 'text-orange underline'
                                      : ''
                                  }`}
                                  data-id={'new'}
                                  key={'new'}
                                  onClick={() => {
                                    setSearchCategoryFilter({
                                      label: 'New Products',
                                      url: 'new',
                                    });
                                    setIsSearchBarFilterOpen(false);
                                  }}
                                >
                                  New Products
                                </li>
                                {navMenu?.productCategory.map((category) => {
                                  return (
                                    <li
                                      className={`mb-4 last:mb-0 ${
                                        searchCategoryFilter.url === category.url
                                          ? 'text-orange underline'
                                          : ''
                                      }`}
                                      data-id={slugify(category.name)}
                                      key={slugify(category.name)}
                                      onClick={() => {
                                        setSearchCategoryFilter({
                                          label: category.name,
                                          url: category.url,
                                        });
                                        setIsSearchBarFilterOpen(false);
                                      }}
                                    >
                                      {/* Category Name Here */}
                                      {category.name}
                                    </li>
                                  );
                                })}
                                <li
                                  className={`mb-4 last:mb-0 ${
                                    searchCategoryFilter.url === 'firerated'
                                      ? 'text-orange underline'
                                      : ''
                                  }`}
                                  data-id={'firerated'}
                                  key={'firerated'}
                                  onClick={() => {
                                    setSearchCategoryFilter({
                                      label: 'Fire Rated Products',
                                      url: 'firerated',
                                    });
                                    setIsSearchBarFilterOpen(false);
                                  }}
                                >
                                  Fire Rated Products
                                </li>
                              </ul>
                            )}
                          </div>
                          <input
                            type="search"
                            name="search-input-mobile"
                            maxLength={150}
                            id="search-input-mobile"
                            className="search-field__input"
                            spellCheck="false"
                            aria-label="Search for a product, category, or brand"
                            aria-placeholder="Search for a product, category, or brand"
                            placeholder="Search for a product, category, or brand"
                            autoCapitalize="off"
                            autoComplete="off"
                            autoCorrect="off"
                            onClick={() => {
                              setIsSearchOpen(true);
                              setIsCROMenuOpen(false);
                              setIsTopRatedBrandsOpen(false);
                              setIsView50Brands(false);
                              document.body.style.overflow = 'hidden';
                            }}
                            onChange={(e) => setSearchString(e.target.value)}
                            value={searchString}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter' && searchString !== '') {
                                handleClickSearch();
                              }
                            }}
                          />
                          <span className="absolute top-0 right-searchHeader bottom-0 flex items-center">
                            <button
                              id="header-search-icon-xl"
                              className={`${
                                companyID === Company.Ironmongery ? 'bg--orange' : 'bg-green'
                              }bottom-searchHeader flex items-center justify-center w-8/50 h-8/50 border-none p-0 rounded-full focus:outline-none ${
                                companyID === Company.Ironmongery
                                  ? 'bg-orange-dark'
                                  : 'bg-green-dark'
                              } `}
                              aria-label="Search"
                              title="Search"
                              onClick={() => {
                                if (searchString !== '') {
                                  handleClickSearch();
                                }
                              }}
                            >
                              <svg
                                width="16"
                                height="15"
                                viewBox="0 0 16 15"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g transform="translate(.5)" stroke="#242424" fill="none">
                                  <circle cx="5.5" cy="5.5" r="4.75"></circle>
                                  <rect
                                    transform="rotate(-45 11.357 11.457)"
                                    x="11.107"
                                    y="8.207"
                                    width="1"
                                    height="6.5"
                                    rx=".5"
                                  ></rect>
                                </g>
                              </svg>
                            </button>
                          </span>
                        </div>

                        {isSearchOpen && (
                          <div
                            className={`search-dropdown__label ${
                              searchCategoryFilter.url !== 'all' ? 'flex md:hidden' : 'hidden'
                            }`}
                          >
                            <p>{searchCategoryFilter.label}</p>
                            <button
                              onClick={() =>
                                setSearchCategoryFilter({
                                  label: 'All',
                                  url: 'all',
                                })
                              }
                            >
                              <svg
                                focusable="false"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512"
                              >
                                <path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path>
                              </svg>
                            </button>
                          </div>
                        )}

                        <div
                          className={`border-t-1 border-solid border-black-order-sum-b py-6 px-0 ${
                            isSearchOpen ? 'block' : 'hidden'
                          }`}
                        >
                          {!isFetchingSearch &&
                            searchString === '' &&
                            searchResultKeywords.length <= 0 &&
                            searchResultProducts.length <= 0 && (
                              <div>
                                <div className="text-white mb-5">
                                  <p className="font-bold leading-6 text-18px md:text-20px md:leading-7 lg:leading-6 lg:text-18px">
                                    Most popular searches
                                  </p>
                                </div>
                                <ol>
                                  {popularSearches.map((query) => {
                                    return (
                                      <li key={query.query}>
                                        <div className="block cursor-pointer">
                                          <a
                                            className="w-full no-underline inline-flex py-3.5 text-white hover:bg-grey-primary pr-4 pl-12"
                                            role="button"
                                            tabIndex={0}
                                            href={`/search?query=${query.query}`}
                                          >
                                            <div className="flex items-center">
                                              <div
                                                className={`mr-5 ${
                                                  query.fireIcon
                                                    ? 'h-18px w-3 bg-fire-icon'
                                                    : 'h-14px w-14px bg-search-icon'
                                                }`}
                                              ></div>
                                              <div>{query.query}</div>
                                            </div>
                                          </a>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ol>
                              </div>
                            )}
                          {isFetchingSearch || searchResultKeywords.length <= 0 ? null : (
                            <div>
                              <ol>
                                {searchResultKeywords.map((keyword, index) => (
                                  <li key={`keyword-${index}`}>
                                    <div
                                      // className={`block cursor-pointer ${
                                      //   activeKeyword === index ? 'pointer-events-none' : ''
                                      // }`}
                                      className="block cursor-pointer"
                                    >
                                      <Link href={`/search?query=${keyword.searchTerm}`}>
                                        <a
                                          className="w-full no-underline inline-flex py-3.5 text-white rounded-5px hover:bg-grey-primary pr-4 pl-12"
                                          // onMouseEnter={() =>
                                          //   handleOnMouseEnter(index, keyword.searchTerm)
                                          // }
                                          // onMouseLeave={() => clearTimeout(cursorEnter)}
                                          role="button"
                                          tabIndex={0}
                                          onClick={handleClickReset}
                                        >
                                          <div>
                                            <div
                                              className={`search-box__keywords ${
                                                activeKeyword === index ? 'selected' : ''
                                              }`}
                                              dangerouslySetInnerHTML={{
                                                __html: keywordMatchHelper(
                                                  searchString,
                                                  keyword.searchTerm
                                                ),
                                              }}
                                            ></div>
                                          </div>
                                        </a>
                                      </Link>
                                    </div>
                                  </li>
                                ))}
                              </ol>
                            </div>
                          )}
                        </div>
                        {isFetchingSearch ? (
                          <div
                            className={`border-t-1 border-solid border-black-order-sum-b py-6 px-0 ${
                              isSearchOpen ? 'block' : 'hidden'
                            }`}
                          >
                            <div className="w-full flex items-center justify-center">
                              <SyncLoader
                                sizeUnit="px"
                                size={20}
                                margin="10px"
                                color={'#393B3B'}
                                loading={true}
                              />
                            </div>
                          </div>
                        ) : searchResultProducts.length > 0 ? (
                          <div
                            className={`border-t-1 border-solid border-black-order-sum-b py-6 px-0 ${
                              isSearchOpen ? 'block' : 'hidden'
                            }`}
                          >
                            <div className="relative">
                              {/* ProductTitle */}
                              <div className="text-white mb-5">
                                <p className="font-bold leading-6 text-18px md:text-20px md:leading-7 lg:leading-6 lg:text-18px">
                                  Product Suggestions
                                </p>
                              </div>
                              {/* Product Carousel */}
                              <Carousel
                                draggable={true}
                                content={searchResultProducts.map((item, index) => (
                                  <div className="carousel__slide" key={`carousel-slide-${index}`}>
                                    <Link href={item.url}>
                                      <a
                                        className="suggestions__anchor"
                                        role="button"
                                        tabIndex={0}
                                        onClick={handleClickReset}
                                      >
                                        <div className="suggestions__img">
                                          <img src={item.thumbUrl} alt={item.name} />
                                        </div>
                                        <div className="suggestions__name">
                                          <p>{item.name}</p>
                                        </div>
                                        <div className="suggestions__price">
                                          <p>
                                            {`£${item.price} `}
                                            <span>+VAT</span>
                                          </p>
                                        </div>
                                      </a>
                                    </Link>
                                  </div>
                                ))}
                                companyID={companyID}
                                direction={'x'}
                              />
                              {/* Product CTAs */}
                              <div className="mt-4">
                                <Link href={`/search?query=${searchString}`}>
                                  <a
                                    className={`cta-btn${
                                      companyID === Company.Ironmongery ? '--id' : '--ed'
                                    }`}
                                    role="button"
                                    tabIndex={0}
                                    onClick={handleClickReset}
                                  >
                                    View All Products
                                  </a>
                                </Link>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                {/* BASKET BUTTONS */}
                <ul className="flex flex-row order-5 mb-0 ml-auto md:ml-8 1192px:ml-15 items-center">
                  <li>
                    <Link href="/contact">
                      <a
                        id="header-contact-xl"
                        className="head-btn head-btn--id p-0"
                        // title="Contact Us"
                        // aria-label="Contact Us"
                        // data-control="contact-aside"
                        // onClick={() => {
                        //   setIsAccountTabOpen(!isAccountTabOpen);
                        //   setAccountTab(1);
                        //   document.body.style.overflow = 'hidden';
                        // }}
                      >
                        <span className="utl-span">
                          <svg
                            className="utl-svg"
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.32.709c-.151-.03-.334-.03-.456.03-1.643.64-2.677 2.99-2.92 4.607-.761 5.248 3.377 9.794 7.18 12.784 3.377 2.654 9.859 7.017 13.692 2.806.487-.518 1.065-1.28 1.035-2.044-.091-1.281-1.278-2.196-2.19-2.898-.7-.519-2.161-1.953-3.074-1.922-.822.03-1.339.885-1.856 1.403l-.913.916c-.152.152-2.1-1.13-2.312-1.282a15.533 15.533 0 01-2.222-1.739 14.374 14.374 0 01-1.795-2.075c-.152-.213-1.4-2.074-1.278-2.227 0 0 1.065-1.16 1.37-1.617.638-.976 1.125-1.739.395-2.898-.274-.427-.609-.763-.974-1.129-.639-.61-1.278-1.25-2.008-1.77C5.6 1.35 4.93.8 4.321.71z"
                              fillRule="nonzero"
                            ></path>
                          </svg>
                        </span>
                      </a>
                    </Link>
                  </li>
                  <li className="ml-4">
                    <button
                      type="button"
                      id="header-account"
                      className="head-btn head-btn--id"
                      title="Account"
                      aria-label="Account"
                      data-control="account-aside"
                      onClick={() => {
                        setIsAccountTabOpen(!isAccountTabOpen);
                        setAccountTab(2);
                        setIsCROMenuOpen(false);
                        setIsTopRatedBrandsOpen(false);
                        setIsView50Brands(false);
                        document.body.style.overflow = 'hidden';
                      }}
                    >
                      <span className="utl-span">
                        <svg
                          className="utl-svg"
                          width="20"
                          height="22"
                          viewBox="0 0 20 22"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 11c3.156 0 5.714-2.462 5.714-5.5S13.156 0 10 0C6.844 0 4.286 2.462 4.286 5.5S6.844 11 10 11zm4 1.375h-.746a8.035 8.035 0 01-6.508 0H6c-3.313 0-6 2.587-6 5.775v1.788C0 21.075.96 22 2.143 22h15.714C19.04 22 20 21.076 20 19.937V18.15c0-3.188-2.688-5.775-6-5.775z"
                            fillRule="nonzero"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </li>
                  <li className="ml-4">
                    <button
                      type="button"
                      id="header-basket"
                      className="head-btn head-btn--id"
                      title="Basket"
                      aria-label="Basket"
                      data-control="basket-aside"
                      onClick={() => {
                        setIsAccountTabOpen(!isAccountTabOpen);
                        setAccountTab(3);
                        setIsCROMenuOpen(false);
                        setIsTopRatedBrandsOpen(false);
                        setIsView50Brands(false);
                        document.body.style.overflow = 'hidden';
                      }}
                    >
                      <span className="utl-span">
                        <svg
                          className="utl-svg"
                          width="22"
                          height="23"
                          viewBox="0 0 22 23"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g transform="translate(0 .41)" fillRule="evenodd">
                            <path
                              d="M.351 7.528c-.468 0-.468.881 0 2.644l2.22 10.267a1.675 1.675 0 001.551 1.09h13.756c.682-.001 1.295-.432 1.55-1.09l2.22-10.267c.47-1.763.47-2.644 0-2.644H.352z"
                              fillRule="nonzero"
                            ></path>
                            <rect
                              transform="rotate(55 14.787 5)"
                              x="10.287"
                              y="4.5"
                              width="9"
                              height="1"
                              rx=".5"
                            ></rect>
                            <rect
                              transform="scale(-1 1) rotate(55 0 -8.856)"
                              x="2.713"
                              y="4.5"
                              width="9"
                              height="1"
                              rx=".5"
                            ></rect>
                          </g>
                        </svg>
                        <div id="ReactHeaderQty">
                          {basketItems && basketItems.length > 0 && (
                            <div className="header-basket__qty">
                              {!basketLoading ? (
                                basketItems.reduce(
                                  function (prev: { sum: number }, curr: Basket) {
                                    prev.sum += curr.quantity;
                                    return prev;
                                  },
                                  { sum: 0 }
                                ).sum
                              ) : (
                                <InfiniteLoader className="header-basket__loader" />
                              )}
                            </div>
                          )}
                        </div>
                      </span>
                    </button>
                    <span id="dy-cart-value" className="hidden">
                      {basketTotal.toFixed(2)}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
      </div>

      <HeaderBanner />
    </>
  );
};

export default NavHeader;
