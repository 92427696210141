import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getBasket, mergeGuestItemsToBasket } from 'src/redux/basketSlice';
import {
  selectAddresses,
  selectPostCode,
  setAccountDetails,
  setCardCode,
  setPostCode,
} from 'src/redux/userSlice';

import Link from 'next/link';
import { useRouter } from 'next/router';

import axios from 'axios';
import { PulseLoader } from 'react-spinners';

import { checkoutLink } from '@utils/checkoutLink';
import dateToMMDDYYYY from '@utils/dateToMMDDYYYY';
import { fbEvent } from '@utils/facebook';
import getCookies from '@utils/getCookies';

import Company from 'types/company';
import { FbEventName } from 'types/facebook';
import Source from 'types/source';
import { FindItem, FindResponse, GeoLocationItem, RetrieveResponse } from 'types/loqate';
import useGeoLocation from '@hooks/useGeolocation';
import { Form, Formik, FormikProps } from 'formik';
import PostcodeForm from '@components/TradeAccount/components/ID/postcode-form';
import { AddressForm, Country } from 'types/address';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

declare const turnstile: any;
declare global {
  interface Window {
    onloadTurnstileCallback: () => void;
  }
}

interface Props {
  onRegister: () => void;
  onReset: () => void;
}

interface RegisterFormProps {
  title: string;
  firstName: {
    value: string;
    isTouched: boolean;
  };
  lastName: {
    value: string;
    isTouched: boolean;
  };
  emailAddress: {
    value: string;
    isTouched: boolean;
    isAlreadyUsed: boolean;
  };
  phoneNumber: {
    value: string;
    isTouched: boolean;
  };
  password: {
    value: string;
    isTouched: boolean;
    isCapsLockOn: boolean;
  };
  repeatPassword: {
    value: string;
    isTouched: boolean;
    isCapsLockOn: boolean;
  };
  heard: {
    value: string;
    isTouched: boolean;
  };
  usedFor: {
    value: string | number;
    companyName: string;
  };
  notify: boolean;
  postCode: {
    value: string;
    isTouched: boolean;
  };
  buildingName: {
    value: string;
    isTouched: boolean;
  };
  street: {
    value: string;
    isTouched: boolean;
  };
  city: {
    value: string;
    isTouched: boolean;
  };
  county: {
    value: string;
    isTouched: boolean;
  };
}

import { APIResponse } from 'types/common';
import AddressSelect from '@components/TradeAccount/components/ID/address-select';
import { processBuilding, processStreet, processTown } from '@utils/processAddress';
import Button, { Size, Variant } from '@components/button';
import Head from 'next/head';

interface PostCodeLookupAPI extends APIResponse, FindResponse {}
interface RetrieveAPI extends APIResponse, RetrieveResponse {}

const lengthMaxMessage = 'Please enter less than 30 characters.';
const postCodeRegex =
  /^(([A-Z]{1,2}\d[A-Z\d]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?\d[A-Z]{2}|BFPO ?\d{1,4}|(KY\d|MSR|VG|AI)[ -]?\d{4}|[A-Z]{2} ?\d{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/i;

const AccountSignup = ({ onRegister, onReset }: Props): JSX.Element => {
  const companyID = Number(process.env.NEXT_PUBLIC_COMPANY_ID);
  const baseUrl = process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL;
  const apiKey = process.env.NEXT_PUBLIC_MANUTAN_CHECKOUT_API_KEY;
  const router = useRouter();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [registerForm, setRegisterForm] = useState<RegisterFormProps>({
    title: 'Mr',
    firstName: {
      value: '',
      isTouched: false,
    },
    lastName: {
      value: '',
      isTouched: false,
    },
    emailAddress: {
      value: '',
      isTouched: false,
      isAlreadyUsed: false,
    },
    phoneNumber: {
      value: '',
      isTouched: false,
    },
    password: {
      value: '',
      isTouched: false,
      isCapsLockOn: false,
    },
    repeatPassword: {
      value: '',
      isTouched: false,
      isCapsLockOn: false,
    },
    heard: {
      value: '',
      isTouched: false,
    },
    usedFor: {
      value: '',
      companyName: '',
    },
    postCode: {
      value: '',
      isTouched: false,
    },
    buildingName: {
      value: '',
      isTouched: false,
    },
    street: {
      value: '',
      isTouched: false,
    },
    city: {
      value: '',
      isTouched: false,
    },
    county: {
      value: '',
      isTouched: false,
    },
    notify: false,
  });
  const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);

  const [sources, setSources] = useState<Source[]>([]);
  const environment = process.env.NEXT_PUBLIC_APP_ENV;
  const [isSourcesOpen, setIsSourcesOpen] = useState<boolean>(false);
  const [selectedSource, setSelectedSource] = useState<Source>();
  const [sourceInput, setSourceInput] = useState<string>('');
  const [filteredSources, setFilteredSources] = useState<Source[]>();
  const [isSubmitValid, setIsSubmitValid] = useState<boolean>(false);
  const [hover, setHover] = useState<string>('');
  const [turnstileToken, setTurnsTileToken] = useState<string>('');

  const { cardCode, details } = useAppSelector((state) => state.user);
  const { basketId } = useAppSelector((state) => state.basket);

  const turnstileKey = process.env.NEXT_PUBLIC_TURNSTILE_CLIENT_KEY;
  const [turnstileError, setTurnstileError] = useState<boolean>(false);

  const filterSources = (input: string): Source[] => {
    return sources.filter((source) => source.name.toLowerCase().includes(input.toLowerCase()));
  };

  const checkForSpecialCharacters = (text: string): boolean => {
    // const regexForValidation = /^\S+(?!-+$)[a-z -]+$/i;
    const regexForValidation = /^\S+[a-zA-Z -]{0,30}$/;
    return regexForValidation.test(text);
  };

  const checkForWhiteSpaceOnly = (input: string): boolean => {
    const regexForValidationWhiteSpaceOnly = /^\S*$/;
    return regexForValidationWhiteSpaceOnly.test(input);
  };

  const handleOnSubmit = (): void => {
    setIsFormVisible(true);
    setIsSubmitClicked(true);
    setTurnstileError(false);

    const validateCompanyName = (): boolean => {
      if (registerForm.usedFor.value === 111 || registerForm.usedFor.value === 113) {
        return registerForm.usedFor.companyName !== '';
      } else {
        return true;
      }
    };

    const isCompanyNameValid = validateCompanyName();

    const isValuesValid =
      registerForm.firstName.value !== '' &&
      checkForSpecialCharacters(registerForm.firstName.value) &&
      registerForm.lastName.value !== '' &&
      checkForSpecialCharacters(registerForm.lastName.value) &&
      registerForm.emailAddress.value !== '' &&
      /\S+@\S+\.\S+/.test(registerForm.emailAddress.value) &&
      !registerForm.emailAddress.isAlreadyUsed &&
      registerForm.phoneNumber.value !== '' &&
      registerForm.phoneNumber.value.length === 11 &&
      registerForm.password.value !== '' &&
      registerForm.password.value.length >= 6 &&
      registerForm.repeatPassword.value !== '' &&
      registerForm.repeatPassword.value === registerForm.password.value &&
      registerForm.usedFor.value !== '' &&
      isCompanyNameValid &&
      registerForm.postCode.value !== '' &&
      postCodeRegex.test(registerForm.postCode.value) &&
      registerForm.buildingName.value !== '' &&
      registerForm.buildingName.value.length <= 30 &&
      registerForm.street.value !== '' &&
      registerForm.street.value.length <= 30 &&
      registerForm.city.value.length <= 30 &&
      registerForm.county.value.length <= 30;

    if (isValuesValid) {
      setIsSubmitting(true);
      setIsSubmitValid(false);
      axios({
        method: 'POST',
        url: `${baseUrl}/account/NewCustomer?companyID=${companyID}`,
        headers: {
          'ocp-apim-subscription-key': apiKey,
          // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
        },
        data: {
          title: registerForm.title,
          firstName: registerForm.firstName.value,
          lastName: registerForm.lastName.value,
          email: registerForm.emailAddress.value,
          password: registerForm.password.value,
          acceptsMarketing: registerForm.notify,
          otherSource: registerForm.heard.value,
          phoneNumber: registerForm.phoneNumber.value,
          segment: registerForm.usedFor,
          invoiceAddress: {
            addressLineOne: registerForm.street.value,
            addressLineTwo: registerForm.city.value,
            addressLineThree: registerForm.county.value,
            houseName: registerForm.buildingName.value,
            postCode: registerForm.postCode.value.replace(/ /g, ''),
            name: `${
              registerForm.buildingName.value ? `${registerForm.buildingName.value}, ` : ''
            }${registerForm.street.value ? `${registerForm.street.value}, ` : ''}${
              registerForm.city.value ? `${registerForm.city.value}, ` : ''
            }${registerForm.county.value ? `${registerForm.county.value}, ` : ''}${
              registerForm.postCode.value ? `${registerForm.postCode.value}, ` : ''
            }${Country.GB}`,
          },
          turnstileToken,
        },
      })
        .then(() => {
          window.dataLayer.push({
            event: 'Registration Submit',
            customerSegment:
              +registerForm.usedFor.value === 111 || +registerForm.usedFor.value === 113
                ? 'Business'
                : 'personal',
          });

          fbEvent({
            eventName: FbEventName.COMPLETE_REGISTRATION,
            eventSourceUrl: router.asPath,
            email: registerForm.emailAddress.value,
            firstName: registerForm.firstName.value,
            lastName: registerForm.lastName.value,
            status: 'successful',
          });

          axios({
            method: 'POST',
            url: `${baseUrl}/account/api/Auth/Login?companyID=${companyID}`,
            headers: {
              'ocp-apim-subscription-key': apiKey,
              // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
            },
            data: {
              username: registerForm.emailAddress.value,
              password: registerForm.password.value,
            },
          })
            .then((response) => {
              setIsSubmitting(false);
              dispatch(setCardCode({ companyID, baseUrl, apiKey, token: response.data }));
              document.cookie = `authToken=${response.data};path=/; ${
                environment === 'production'
                  ? `domain=.${
                      companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                    }direct.co.uk;`
                  : ''
              }`;
              document.cookie = `authEmail=${registerForm.emailAddress.value};path=/; ${
                environment === 'production'
                  ? `domain=.${
                      companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                    }direct.co.uk;`
                  : ''
              }`;
              window.ScarabQueue?.push(['setEmail', registerForm.emailAddress.value]);
              window.ScarabQueue?.push(['go']);
              // localStorage.removeItem('guestBasket');
              // if (redirectUrl === '/checkout') {
              //   setRedirectToCheckout(true);
              // } else {
              //   router.push(redirectUrl);
              // }
              onRegister();
            })
            .catch(() => {
              document.cookie = `authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;${
                environment === 'production'
                  ? `domain=.${
                      companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                    }direct.co.uk;`
                  : ''
              }`;
              document.cookie = `authEmail=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;${
                environment === 'production'
                  ? `domain=.${
                      companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                    }direct.co.uk;`
                  : ''
              }`;
              setIsSubmitting(false);
            });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setTurnstileError(true);
          }

          fbEvent({
            eventName: FbEventName.COMPLETE_REGISTRATION,
            eventSourceUrl: router.asPath,
            email: registerForm.emailAddress.value,
            firstName: registerForm.firstName.value,
            lastName: registerForm.lastName.value,
            status: 'failed',
          });
          setIsSubmitting(false);
        });
    } else {
      setIsSubmitValid(true);
    }
  };

  useEffect(() => {
    if (sourceInput.length > 1) {
      setFilteredSources(filterSources(sourceInput));
      setIsSourcesOpen(true);
    } else {
      setIsSourcesOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceInput]);

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${baseUrl}/account/SourceCode?companyID=${companyID}`,
      headers: {
        'Ocp-Apim-Subscription-Key': apiKey,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status === 200) {
          const sources = response.data;
          // sort sources alphabetically
          setSources(sources.sort((a, b) => a.name.localeCompare(b.name)));
        }
      })
      .catch((error) => console.error(error));
  }, [apiKey, baseUrl, companyID]);

  useEffect(() => {
    const authToken = getCookies('authToken');
    const authEmail = getCookies('authEmail');
    if (cardCode !== '' && authToken && authEmail) {
      dispatch(
        setAccountDetails({
          companyID,
          baseUrl,
          apiKey,
          email: authEmail,
          cardCode,
        })
      );

      dispatch(getBasket({ companyID, baseUrl, apiKey, token: authToken }));
    }
  }, [cardCode, companyID, baseUrl, apiKey, dispatch]);

  useEffect(() => {
    // if (!redirectToCheckout) {
    //   return;
    // }

    const authToken = getCookies('authToken');
    const authEmail = getCookies('authEmail');
    const guestBasket = localStorage.getItem('guestBasket');

    if (!authToken || !authEmail || !details || !cardCode || !basketId || !guestBasket) {
      return;
    }

    const process = async () => {
      try {
        await appDispatch(
          mergeGuestItemsToBasket({
            companyID,
            baseUrl,
            apiKey,
            basketId,
            accountCode: cardCode,
            emailAddress: authEmail,
            guestBasketItems: JSON.parse(guestBasket),
            token: authToken,
          })
        ).unwrap();
        // handle result here

        localStorage.removeItem('guestBasket');

        document.cookie = `IDLWebUser=Username=${details.emailAddress}&LastVisited=${dateToMMDDYYYY(
          new Date()
        )}&activeCompany=${
          companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
        }direct.co.uk&CardCode=${cardCode}&FirstName=${details.firstName};path=/;domain=.${
          companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
        }direct.co.uk;`;
        document.cookie = `IDLBasket=${basketId};path=/;domain=.${
          companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
        }direct.co.uk;`;

        window.location.href = checkoutLink(companyID, {
          UserName: details.emailAddress,
          LastVisited: dateToMMDDYYYY(new Date()),
          ActiveCompany: `${
            companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
          }direct.co.uk`,
          CardCode: cardCode,
          IDLBasket: basketId,
        });
      } catch (rejectedValueOrSerializedError) {
        // handle error here
      }
    };

    process();
  }, [details, cardCode, basketId, companyID, apiKey, baseUrl, dispatch]);

  const [isFetchingAddress, setIsFetchingAddress] = useState<boolean>(false);
  const [isFetchingAddressById, setIsFetchingAddressById] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<string>('');
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [addressList, setAddressList] = useState<FindItem[] | GeoLocationItem[] | null>(null);
  const reduxUserAddresses = useAppSelector(selectAddresses);
  const postcode = useAppSelector(selectPostCode);

  useEffect(() => {
    if (formikRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formikRef.current.setFieldValue('postcode', postcode);
    }
  }, [postcode]);

  const formikRef = useRef<any>();

  const fetchGeolocationLookup = async ({
    latitude,
    longitude,
  }: {
    latitude: number;
    longitude: number;
  }): Promise<void> => {
    setFetchError('');
    setAddressList(null);

    try {
      const { data: geoReverseData }: { data: { statusCode: number; postcode: string } } =
        await axios.post('/api/geoReverse', {
          latitude,
          longitude,
        });
      if (geoReverseData.statusCode === 200) {
        setGeolocationStatus('Success');
        dispatch(setPostCode(geoReverseData.postcode));
      } else {
        throw 'Failed';
      }
    } catch (err) {
      setGeolocationStatus('Failed');
      setFetchError(
        'Unable to find your location, please try entering manually or looking up your postcode.'
      );
    }
  };

  const {
    status: geolocationStatus,
    setStatus: setGeolocationStatus,
    getLocation,
  } = useGeoLocation(fetchGeolocationLookup);

  const fetchPostcodeLookup = async (postcode: string, containerId?: string): Promise<void> => {
    setIsFetchingAddress(true);
    setFetchError('');
    try {
      const { data }: { data: PostCodeLookupAPI } = await axios.post('/api/postcodeLookup', {
        text: postcode,
        containerId,
      });

      if (data.statusCode >= 200 && data.statusCode <= 299) {
        setIsFetchingAddress(false);

        if (data.Items.length === 1) {
          const item = data.Items[0] as FindItem;

          if (item.Type === 'Postcode') {
            fetchPostcodeLookup(postcode, item.Id);
          }
        }
        const items = data.Items as FindItem[];
        setAddressList(items);
      }
    } catch (err) {
      formikRef.current?.resetForm();
      setIsFetchingAddress(false);
      setFetchError(
        'Unable to find addresses for this postcode, please enter manually or try a different postcode.'
      );
    }
  };

  const handleEnterManually = (): void => {
    setIsFormVisible(true);
  };

  const handleFormSubmit = (postcode: string): void => {
    setIsFormVisible(false);
    setAddressList(null);
    fetchPostcodeLookup(encodeURI(postcode));
  };

  useEffect(() => {
    window.onloadTurnstileCallback = () => {
      turnstile.render('#turnstile-container', {
        sitekey: turnstileKey,
        theme: 'light',
        callback: (token) => {
          setTurnsTileToken(token);
        },
      });
    };
  }, []);

  return (
    <>
      <Head>
        {/* Cloudflare Turnstile */}
        <script
          src="https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit&onload=onloadTurnstileCallback"
          async
        ></script>
      </Head>
      <div className="register-page__inner">
        <div className="title my-5 lg:my-7 w-full lg:w-1/2 lg:pr-3">
          <p className="form-label -top-4 text-sm text-grey-light">Title *</p>
          <select
            id="Title"
            name="title"
            onBlur={(e) => {
              setRegisterForm({ ...registerForm, title: e.target.value });
            }}
            className="w-full border-b-1 border-grey-light py-2 bg-white"
          >
            <option value="Mr">Mr</option>
            <option value="Ms">Ms</option>
            <option value="Mrs">Mrs</option>
            <option value="Miss">Miss</option>
          </select>
        </div>
        <div className="flex flex-col lg:flex-row gap-6 mb-4">
          <div className="name mb-6 md:mb-0 relative mt-4 w-full lg:w-1/2">
            <p
              className={`absolute left-0 top-0 z-0 duration-300 ${
                registerForm.firstName.value !== '' ? '-top-4 text-sm text-grey-light' : ''
              }`}
            >
              First Name *
            </p>
            <input
              autoCapitalize="sentences"
              autoComplete="given-name"
              autoCorrect="off"
              className={`relative appearance-none bg-transparent w-full pr-8 pt-4 pb-2 border-b focus:outline-none z-1 ${
                isSubmitClicked || registerForm.firstName.isTouched
                  ? registerForm.firstName.value === '' ||
                    !checkForSpecialCharacters(registerForm.firstName.value)
                    ? 'border-error'
                    : 'border-valid'
                  : 'border-grey-light'
              }`}
              data-val="true"
              data-val-required="Required"
              id="FirstName"
              name="firstName"
              type="text"
              value={registerForm.firstName.value}
              onChange={(e) =>
                setRegisterForm({
                  ...registerForm,
                  firstName: {
                    ...registerForm.firstName,
                    value: e.target.value,
                  },
                })
              }
              onBlur={() =>
                setRegisterForm({
                  ...registerForm,
                  firstName: { ...registerForm.firstName, isTouched: true },
                })
              }
            />
            <div className="messages">
              <div
                className={`form-verification ${
                  isSubmitClicked || registerForm.firstName.isTouched
                    ? registerForm.firstName.value === ''
                      ? ''
                      : 'hidden'
                    : 'hidden'
                }`}
              >
                <p className="text-xs text-red mt-1">First name required.</p>
              </div>
              <div
                className={`form-verification ${
                  registerForm.firstName.value !== '' && registerForm.firstName.isTouched
                    ? !checkForSpecialCharacters(registerForm.firstName.value)
                      ? ''
                      : 'hidden'
                    : 'hidden'
                }`}
              >
                <p className="text-xs text-red mt-1">
                  Your first name cannot contain any special characters or numbers, only letters and
                  hyphens are allowed. Please check what has been entered.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div className="name--alt mb-6 md:mb-0 relative xs:mt-4">
              <p
                className={`absolute left-0 top-0 z-0 duration-300 ${
                  registerForm.lastName.value !== '' ? '-top-4 text-sm text-grey-light' : ''
                }`}
              >
                Last Name *
              </p>
              <input
                autoCapitalize="sentences"
                autoComplete="family-name"
                autoCorrect="off"
                className={`relative appearance-none bg-transparent w-full pr-8 pt-4 pb-2 border-b focus:outline-none z-1 ${
                  isSubmitClicked || registerForm.lastName.isTouched
                    ? registerForm.lastName.value === '' ||
                      !checkForSpecialCharacters(registerForm.lastName.value)
                      ? 'border-error'
                      : 'border-valid'
                    : 'border-grey-light'
                }`}
                id="LastName"
                name="lastName"
                type="text"
                value={registerForm.lastName.value}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    lastName: {
                      ...registerForm.lastName,
                      value: e.target.value,
                    },
                  })
                }
                onBlur={() =>
                  setRegisterForm({
                    ...registerForm,
                    lastName: { ...registerForm.lastName, isTouched: true },
                  })
                }
              />
            </div>
            <div className="messages">
              <div
                className={`form-verification ${
                  isSubmitClicked || registerForm.lastName.isTouched
                    ? registerForm.lastName.value === ''
                      ? ''
                      : 'hidden'
                    : 'hidden'
                }`}
              >
                <p className="text-xs text-red mt-1">Last name required.</p>
              </div>
              <div
                className={`form-verification ${
                  registerForm.lastName.value !== '' && registerForm.lastName.isTouched
                    ? !checkForSpecialCharacters(registerForm.lastName.value)
                      ? ''
                      : 'hidden'
                    : 'hidden'
                }`}
              >
                <p className="text-xs text-red mt-1">
                  Your last name cannot contain any special characters or numbers, only letters and
                  hyphens are allowed. Please check what has been entered.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-6 mb-4">
          {/* Email */}
          <div className="form-row w-full lg:w-1/2">
            <div className="details mb-6 md:mb-0 relative xs:mt-4">
              <p
                className={`absolute left-0 top-0 z-0 duration-300 ${
                  registerForm.emailAddress.value !== '' ? '-top-4 text-sm text-grey-light' : ''
                }`}
              >
                Email Address *
              </p>
              <div
                role="button"
                tabIndex={0}
                className={`right-0 absolute top-0 cursor-pointer z-99 mb-3 ${
                  registerForm.emailAddress.value !== '' ? '-top-4 text-sm text-grey-light' : ''
                }`}
                onClick={() => setHover('email')}
                onMouseEnter={() => setHover('email')}
                onMouseLeave={() => setHover('')}
              >
                <svg
                  xmlns="//www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  {' '}
                  <path
                    d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.905 6.52 11.0007 6.28267 11 6C10.9993 5.71733 10.9033 5.48 10.712 5.288C10.5207 5.096 10.2833 5 10 5C9.71667 5 9.47933 5.096 9.288 5.288C9.09667 5.48 9.00067 5.71733 9 6C8.99933 6.28267 9.09533 6.52033 9.288 6.713C9.48067 6.90567 9.718 7.00133 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88334 18.6867 3.825 17.9743 2.925 17.075C2.025 16.1757 1.31267 15.1173 0.788001 13.9C0.263335 12.6827 0.000667933 11.3827 1.26582e-06 10C-0.000665401 8.61733 0.262001 7.31733 0.788001 6.1C1.314 4.88267 2.02633 3.82433 2.925 2.925C3.82367 2.02567 4.882 1.31333 6.1 0.788C7.318 0.262667 8.618 0 10 0C11.382 0 12.682 0.262667 13.9 0.788C15.118 1.31333 16.1763 2.02567 17.075 2.925C17.9737 3.82433 18.6863 4.88267 19.213 6.1C19.7397 7.31733 20.002 8.61733 20 10C19.998 11.3827 19.7353 12.6827 19.212 13.9C18.6887 15.1173 17.9763 16.1757 17.075 17.075C16.1737 17.9743 15.1153 18.687 13.9 19.213C12.6847 19.739 11.3847 20.0013 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                    fill="#777777"
                  />
                </svg>
                {hover === 'email' && (
                  <div className="p-3 bg-white-darker absolute bottom-180% -right-50% md:-right-220% shadow-lg w-310px rounded-md z-99 border border-white-darkest">
                    <div className="font-bold sm:text-sm text-base mb-3">Email Address</div>
                    <p className="sm:text-sm text-base">
                      Your email is used to send updates on your order, as well as to update account
                      information.
                    </p>
                    <img
                      src="https://d1mgcpums0qvsa.cloudfront.net/IRM/6-0/baloon-arrow.svg"
                      alt="pointer"
                      className="absolute h-4 w-4 z-1 top-97% right-3% md:right-14%"
                    />
                  </div>
                )}
              </div>
              <input
                autoCapitalize="off"
                autoComplete="email"
                autoCorrect="off"
                // className={
                //   isSubmitClicked || registerForm.emailAddress.isTouched
                //     ? registerForm.emailAddress.value === '' ||
                //       !/\S+@\S+\.\S+/.test(registerForm.emailAddress.value) ||
                //       registerForm.emailAddress.isAlreadyUsed
                //       ? 'invalid'
                //       : 'valid'
                //     : ''
                // }
                className={`relative appearance-none bg-transparent w-full pr-8 pt-4 pb-2 border-b focus:outline-none z-1 ${
                  isSubmitClicked || registerForm.emailAddress.isTouched
                    ? registerForm.emailAddress.value === '' ||
                      !/\S+@\S+\.\S+/.test(registerForm.emailAddress.value) ||
                      registerForm.emailAddress.isAlreadyUsed
                      ? 'border-error'
                      : 'border-valid'
                    : 'border-grey-light'
                }`}
                data-val="true"
                data-val-required="Required"
                id="EmailAddress"
                name="emailAddress"
                type="email"
                value={registerForm.emailAddress.value}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    emailAddress: {
                      ...registerForm.emailAddress,
                      value: e.target.value,
                    },
                  })
                }
                onBlur={() => {
                  axios({
                    method: 'GET',
                    url: `${baseUrl}/account/CheckEmailIfValid?emailAddress=${registerForm.emailAddress.value.toLowerCase()}&companyID=${companyID}`,
                    headers: {
                      'ocp-apim-subscription-key': apiKey,
                      // universe:
                      //   companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
                    },
                  })
                    .then((response) => {
                      setRegisterForm({
                        ...registerForm,
                        emailAddress: {
                          ...registerForm.emailAddress,
                          isTouched: true,
                          isAlreadyUsed: response.data.emailAlreadyExist,
                        },
                      });
                    })
                    .catch(() => {
                      setRegisterForm({
                        ...registerForm,
                        emailAddress: {
                          ...registerForm.emailAddress,
                          isTouched: true,
                          isAlreadyUsed: false,
                        },
                      });
                    });
                }}
              />
            </div>
            <div className="messages">
              <div
                className={`form-verification ${
                  isSubmitClicked || registerForm.emailAddress.isTouched
                    ? registerForm.emailAddress.value === ''
                      ? ''
                      : 'hidden'
                    : 'hidden'
                }`}
              >
                <p className="text-xs text-red mt-1">Email address required.</p>
              </div>
              <div
                className={`form-verification ${
                  registerForm.emailAddress.isTouched
                    ? registerForm.emailAddress.value !== '' &&
                      !/\S+@\S+\.\S+/.test(registerForm.emailAddress.value)
                      ? ''
                      : 'hidden'
                    : 'hidden'
                }`}
              >
                <p className="text-xs text-red mt-1">Please enter a valid email address.</p>
              </div>
              <div
                className={`form-verification ${
                  registerForm.emailAddress.isAlreadyUsed ? '' : 'hidden'
                }`}
              >
                <p className="text-xs text-red mt-1">
                  It seems the email address &apos;
                  <span id="form-verification-invalid-email-address">
                    {registerForm.emailAddress.value}
                  </span>
                  &apos; is already registered with us. Please
                  <button onClick={() => onReset()} className="underline">
                    login or reset your password
                  </button>
                  .
                </p>
              </div>
            </div>
          </div>

          {/* Phone Number */}
          <div className="form-row w-full lg:w-1/2">
            <div className="mb-6 md:mb-0 relative xs:mt-4">
              <p
                className={`absolute left-0 top-0 z-0 duration-300 ${
                  registerForm.phoneNumber.value !== '' ? '-top-4 text-sm text-grey-light' : ''
                }`}
              >
                Phone Number *
              </p>
              <div
                role="button"
                tabIndex={0}
                className={`right-0 absolute top-0 cursor-pointer z-99 mb-3 ${
                  registerForm.phoneNumber.value !== '' ? '-top-4 text-sm text-grey-light' : ''
                }`}
                onClick={() => setHover('phoneNumber')}
                onMouseEnter={() => setHover('phoneNumber')}
                onMouseLeave={() => setHover('')}
              >
                <svg
                  xmlns="//www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  {' '}
                  <path
                    d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.905 6.52 11.0007 6.28267 11 6C10.9993 5.71733 10.9033 5.48 10.712 5.288C10.5207 5.096 10.2833 5 10 5C9.71667 5 9.47933 5.096 9.288 5.288C9.09667 5.48 9.00067 5.71733 9 6C8.99933 6.28267 9.09533 6.52033 9.288 6.713C9.48067 6.90567 9.718 7.00133 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88334 18.6867 3.825 17.9743 2.925 17.075C2.025 16.1757 1.31267 15.1173 0.788001 13.9C0.263335 12.6827 0.000667933 11.3827 1.26582e-06 10C-0.000665401 8.61733 0.262001 7.31733 0.788001 6.1C1.314 4.88267 2.02633 3.82433 2.925 2.925C3.82367 2.02567 4.882 1.31333 6.1 0.788C7.318 0.262667 8.618 0 10 0C11.382 0 12.682 0.262667 13.9 0.788C15.118 1.31333 16.1763 2.02567 17.075 2.925C17.9737 3.82433 18.6863 4.88267 19.213 6.1C19.7397 7.31733 20.002 8.61733 20 10C19.998 11.3827 19.7353 12.6827 19.212 13.9C18.6887 15.1173 17.9763 16.1757 17.075 17.075C16.1737 17.9743 15.1153 18.687 13.9 19.213C12.6847 19.739 11.3847 20.0013 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                    fill="#777777"
                  />
                </svg>
                {hover === 'phoneNumber' && (
                  <div className="p-3 bg-white-darker absolute bottom-180% -right-50% md:-right-200% shadow-lg w-310px rounded-md z-99 border border-white-darkest">
                    <div className="font-bold sm:text-sm text-base mb-3">Phone Number</div>
                    <p className="sm:text-sm text-base">
                      Providing your phone number keeps you updated on order status and delivery
                      changes, so you never miss a thing.
                    </p>
                    <img
                      src="https://d1mgcpums0qvsa.cloudfront.net/IRM/6-0/baloon-arrow.svg"
                      alt="pointer"
                      className="absolute h-4 w-4 z-1 top-97% right-3% md:right-14%"
                    />
                  </div>
                )}
              </div>
              <input
                // autoComplete="tel"
                // className={
                //   isSubmitClicked || registerForm.phoneNumber.isTouched
                //     ? registerForm.phoneNumber.value === '' ||
                //       registerForm.phoneNumber.value.length !== 11
                //       ? 'invalid'
                //       : 'valid'
                //     : ''
                // }
                className={`relative appearance-none bg-transparent w-full pr-8 pt-4 pb-2 border-b focus:outline-none z-1 ${
                  isSubmitClicked || registerForm.phoneNumber.isTouched
                    ? registerForm.phoneNumber.value === '' ||
                      registerForm.phoneNumber.value.length !== 11
                      ? 'border-error'
                      : 'border-valid'
                    : 'border-grey-light'
                }`}
                id="Phone1"
                name="Phone1"
                type="number"
                value={registerForm.phoneNumber.value}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    phoneNumber: {
                      ...registerForm.phoneNumber,
                      value: e.target.value,
                    },
                  })
                }
                onBlur={() =>
                  setRegisterForm({
                    ...registerForm,
                    phoneNumber: { ...registerForm.phoneNumber, isTouched: true },
                  })
                }
              />
            </div>
            <div className="messages">
              <div
                className={`form-verification ${
                  isSubmitClicked || registerForm.phoneNumber.isTouched
                    ? registerForm.phoneNumber.value === ''
                      ? ''
                      : 'hidden'
                    : 'hidden'
                }`}
              >
                <p className="text-xs text-red mt-1">Phone number required.</p>
              </div>
              <div
                className={`form-verification ${
                  registerForm.phoneNumber.isTouched
                    ? registerForm.phoneNumber.value !== '' &&
                      registerForm.phoneNumber.value.length !== 11
                      ? ''
                      : 'hidden'
                    : 'hidden'
                }`}
              >
                <p className="text-xs text-red mt-1">
                  UK phone numbers must have 11 numbers in them, please check what has been entered.
                </p>
              </div>
              <div
                className={`form-verification--valid ${
                  registerForm.phoneNumber.isTouched
                    ? registerForm.phoneNumber.value !== '' &&
                      registerForm.phoneNumber.value.length === 11
                      ? ''
                      : 'hidden'
                    : 'hidden'
                }`}
              >
                {/* TODO */}
                {/* <div className="description">
              You’ll receive free delivery notifications on all your orders.
            </div> */}
              </div>
              {/* TODO */}
              {/* <div className="form-verification--info">
            <div className="description">
              If you add a mobile number you will receive free delivery text updates about your
              orders.
            </div>
          </div> */}
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-6 mb-8">
          {/* Password */}
          <div className="form-row w-full lg:w-1/2">
            <div className="details mb-6 md:mb-0 relative xs:mt-4">
              <p
                className={`absolute left-0 top-0 z-0 duration-300 ${
                  registerForm.password.value !== '' ? '-top-4 text-sm text-grey-light' : ''
                }`}
              >
                Password *
              </p>
              <input
                autoComplete="new-password"
                className={`relative appearance-none bg-transparent w-full pr-8 pt-4 pb-2 border-b focus:outline-none z-1 ${
                  isSubmitClicked || registerForm.password.isTouched
                    ? registerForm.password.value === '' ||
                      registerForm.password.value.length < 6 ||
                      !checkForWhiteSpaceOnly(registerForm.password.value)
                      ? 'border-error'
                      : 'border-valid'
                    : 'border-grey-light'
                }`}
                data-val="true"
                data-val-required="Required."
                id="Password"
                name="Password"
                type="password"
                value={registerForm.password.value}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    password: {
                      ...registerForm.password,
                      value: e.target.value,
                    },
                  })
                }
                onBlur={() =>
                  setRegisterForm({
                    ...registerForm,
                    password: { ...registerForm.password, isTouched: true },
                  })
                }
                onKeyUp={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    password: {
                      ...registerForm.password,
                      isCapsLockOn: e.getModifierState('CapsLock'),
                    },
                  })
                }
              />
            </div>
            <div className="messages">
              {/* TODO */}
              {/* <div
            className={`form-verification--warning ${
              registerForm.password.isCapsLockOn ? '' : 'hidden'
            }`}
          >
            <p className="text-xs text-red mt-1">Your CAPS LOCK is on</p>
          </div> */}
              <div
                className={`form-verification ${
                  isSubmitClicked || registerForm.password.isTouched
                    ? registerForm.password.value === ''
                      ? ''
                      : 'hidden'
                    : 'hidden'
                }`}
              >
                <p className="text-xs text-red mt-1">Password required.</p>
              </div>
              <div
                className={`form-verification ${
                  registerForm.password.isTouched
                    ? registerForm.password.value !== '' && registerForm.password.value.length < 6
                      ? ''
                      : 'hidden'
                    : 'hidden'
                }`}
              >
                <p className="text-xs text-red mt-1">
                  Your password must contain at least 6 characters.
                </p>
              </div>
              <div
                className={`form-verification ${
                  registerForm.password.isTouched
                    ? registerForm.password.value !== '' &&
                      !checkForWhiteSpaceOnly(registerForm.password.value)
                      ? ''
                      : 'hidden'
                    : 'hidden'
                }`}
              >
                <p className="text-xs text-red mt-1">Your password contains white space.</p>
              </div>
              {/* TODO */}
              {/* <div
            className={`form-verification--valid ${
              registerForm.password.isTouched
                ? registerForm.password.value !== '' &&
                  registerForm.password.value.length > 5 &&
                  checkForWhiteSpaceOnly(registerForm.password.value)
                  ? ''
                  : 'hidden'
                : 'hidden'
            }`}
          >
            <div className="description">Your password meets our requirements.</div>
          </div> */}
              {/* <div className="form-verification--info">
            <div className="description">Your password must contain at least 6 characters.</div>
          </div> */}
            </div>
          </div>

          {/* Repeat Password */}
          <div className="form-row w-full lg:w-1/2">
            <div className="details mb-6 md:mb-0 relative xs:mt-4">
              <p
                className={`absolute left-0 top-0 z-0 duration-300 ${
                  registerForm.repeatPassword.value !== '' ? '-top-4 text-sm text-grey-light' : ''
                }`}
              >
                Repeat Password *
              </p>
              <input
                autoComplete="new-password"
                className={`relative appearance-none bg-transparent w-full pr-8 pt-4 pb-2 border-b focus:outline-none z-1 ${
                  isSubmitClicked || registerForm.repeatPassword.isTouched
                    ? registerForm.repeatPassword.value === '' ||
                      registerForm.repeatPassword.value !== registerForm.password.value
                      ? 'border-error'
                      : 'border-valid'
                    : 'border-grey-light'
                }`}
                data-val="true"
                data-val-equalto="The new password and confirmation password do not match."
                data-val-equalto-other="*.Password"
                data-val-required="Required"
                id="RepeatPassword"
                name="RepeatPassword"
                type="password"
                value={registerForm.repeatPassword.value}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    repeatPassword: {
                      ...registerForm.repeatPassword,
                      value: e.target.value,
                    },
                  })
                }
                onBlur={() =>
                  setRegisterForm({
                    ...registerForm,
                    repeatPassword: { ...registerForm.repeatPassword, isTouched: true },
                  })
                }
                onKeyUp={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    repeatPassword: {
                      ...registerForm.repeatPassword,
                      isCapsLockOn: e.getModifierState('CapsLock'),
                    },
                  })
                }
              />
            </div>
            <div className="messages">
              {/* TODO */}
              {/* <div
            className={`form-verification--warning ${
              registerForm.repeatPassword.isCapsLockOn ? '' : 'hidden'
            }`}
          >
            <div className="description">Your CAPS LOCK is on</div>
          </div> */}
              <div
                className={`form-verification ${
                  isSubmitClicked || registerForm.repeatPassword.isTouched
                    ? registerForm.repeatPassword.value === ''
                      ? ''
                      : 'hidden'
                    : 'hidden'
                }`}
              >
                <p className="text-xs text-red mt-1">Repeat password required.</p>
              </div>
              <div
                className={`form-verification ${
                  registerForm.repeatPassword.isTouched
                    ? registerForm.repeatPassword.value !== '' &&
                      registerForm.repeatPassword.value !== registerForm.password.value
                      ? ''
                      : 'hidden'
                    : 'hidden'
                }`}
              >
                <p className="text-xs text-red mt-1">Your password must match.</p>
              </div>
            </div>
          </div>
        </div>

        <p className="lg:mb-2 text-#222323 pb-0 lg:p-0">Address Finder</p>
        <Formik
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          innerRef={formikRef}
          initialValues={{
            postcode: '',
            street: '',
            building: '',
            city: '',
            county: '',
            EORI: '',
            default: reduxUserAddresses.length > 0 ? false : true,
          }}
          onSubmit={() => null}
        >
          {(props: FormikProps<AddressForm>) => {
            const { setFieldValue } = props;

            const fetchAddress = async (id: string): Promise<void> => {
              setFetchError('');
              try {
                const { data }: { data: RetrieveAPI } = await axios.post('/api/retrieveLookup', {
                  id,
                });

                if (data.statusCode >= 200 && data.statusCode <= 299) {
                  if (data.Items.length > 0) {
                    const addr = data.Items[0];
                    const postcode = addr.PostalCode;
                    const building = addr.Company
                      ? `${addr.Company}, ${addr.BuildingNumber} ${processBuilding(
                          addr.SubBuilding,
                          addr.BuildingName
                        )}`
                      : `${addr.BuildingNumber} ${processBuilding(
                          addr.SubBuilding,
                          addr.BuildingName
                        )}`;
                    const street = processStreet(
                      // addr.BuildingNumber,
                      addr.Street,
                      addr.SecondaryStreet
                    );
                    const city = processTown(addr.District, addr.City);
                    const county = addr.Province;
                    setRegisterForm({
                      ...registerForm,
                      postCode: {
                        value: postcode,
                        isTouched: true,
                      },
                      buildingName: {
                        value: building,
                        isTouched: true,
                      },
                      street: {
                        value: street,
                        isTouched: true,
                      },
                      city: {
                        value: city,
                        isTouched: true,
                      },
                      county: {
                        value: county,
                        isTouched: true,
                      },
                    });

                    if (!isFormVisible) setIsFormVisible(true);
                  }
                }
              } catch (err) {
                setFetchError('Unable to autofill address, please enter manually.');
              }
            };

            const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
              setIsFetchingAddressById(true);
              if (e.target.value && addressList !== null) {
                const address: FindItem | GeoLocationItem | undefined = addressList.find(
                  (addr: FindItem | GeoLocationItem) => addr.Id === e.target.value
                );

                if (address) {
                  if (address.Type === 'Postcode') {
                    setFieldValue('postcode', address.Text);
                    fetchPostcodeLookup(address.Text, address.Id);
                    setTimeout(() => {
                      setIsFetchingAddressById(false);
                    }, 1000);
                  } else if (address.Type === 'Address') {
                    fetchAddress(address.Id);
                    setTimeout(() => {
                      setIsFetchingAddressById(false);
                    }, 1000);
                  }
                }
              }
            };

            return (
              <Form className="w-full flex flex-col pb-8 lg:p-0">
                <div>
                  <div className="flex flex-col md:flex-row mb-5">
                    <PostcodeForm
                      handleFormSubmit={handleFormSubmit}
                      getLocation={getLocation}
                      geolocationStatus={geolocationStatus}
                      fetchError={fetchError}
                      postcode={postcode}
                      noPaddingBtm
                    />
                  </div>
                  {addressList !== null && addressList.length && (
                    <div className="w-full">
                      <AddressSelect
                        addressList={addressList}
                        handleSelectChange={handleSelectChange}
                      />
                    </div>
                  )}
                </div>
                {isFetchingAddress ? (
                  <div className="w-full md:w-2/3 mt-6">
                    <div className="flex flex-col justify-center items-center">
                      <FontAwesomeIcon icon={faSpinner} pulse className="w-12 h-12" />
                      <p className="mt-3.5 text-center">
                        One moment, retrieving addresses by postcode!
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="inline-block mb-4">
                      <Button
                        type="button"
                        variant={Variant.PLAIN}
                        size={Size.TEXT}
                        onClick={handleEnterManually}
                      >
                        <div className="w-auto flex items-center justify-center align-middle">
                          <span className="underline">Enter address manually</span>
                        </div>
                      </Button>
                    </div>
                    {!isFetchingAddressById ? (
                      isFormVisible && (
                        <>
                          <div className="name mb-6 md:mb-0 relative mt-4 w-full">
                            <p
                              className={`absolute left-0 top-0 z-0 duration-300 ${
                                registerForm.postCode.value !== ''
                                  ? '-top-4 text-sm text-grey-light'
                                  : ''
                              }`}
                            >
                              Postcode *
                            </p>
                            <input
                              autoCapitalize="sentences"
                              autoComplete="off"
                              autoCorrect="off"
                              className={`relative appearance-none bg-transparent w-full pr-8 pt-4 pb-2 border-b focus:outline-none z-1 ${
                                isSubmitClicked || registerForm.postCode.isTouched
                                  ? registerForm.postCode.value === '' ||
                                    !postCodeRegex.test(registerForm.postCode.value)
                                    ? 'border-error'
                                    : 'border-valid'
                                  : 'border-grey-light'
                              }`}
                              data-val="true"
                              data-val-required="Required"
                              id="PostCode"
                              name="postCode"
                              type="text"
                              value={registerForm.postCode.value}
                              onChange={(e) =>
                                setRegisterForm({
                                  ...registerForm,
                                  postCode: {
                                    ...registerForm.postCode,
                                    value: e.target.value,
                                  },
                                })
                              }
                              onBlur={() =>
                                setRegisterForm({
                                  ...registerForm,
                                  postCode: { ...registerForm.postCode, isTouched: true },
                                })
                              }
                            />
                            <div className="messages">
                              <div
                                className={`form-verification ${
                                  isSubmitClicked || registerForm.postCode.isTouched
                                    ? registerForm.postCode.value === ''
                                      ? ''
                                      : 'hidden'
                                    : 'hidden'
                                }`}
                              >
                                <p className="text-xs text-red mt-1">Postcode required.</p>
                              </div>
                              <div
                                className={`form-verification ${
                                  registerForm.postCode.value !== '' &&
                                  registerForm.postCode.isTouched
                                    ? !postCodeRegex.test(registerForm.postCode.value)
                                      ? ''
                                      : 'hidden'
                                    : 'hidden'
                                }`}
                              >
                                <p className="text-xs text-red mt-1">
                                  Please enter a valid UK postcode.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col lg:flex-row gap-6 my-4">
                            {/* Building Name */}
                            <div className="name mb-6 md:mb-0 relative mt-4 w-full lg:w-1/2">
                              <p
                                className={`absolute left-0 top-0 z-0 duration-300 ${
                                  registerForm.buildingName.value !== ''
                                    ? '-top-4 text-sm text-grey-light'
                                    : ''
                                }`}
                              >
                                House No / Building Name *
                              </p>
                              <input
                                autoCapitalize="sentences"
                                autoComplete="off"
                                autoCorrect="off"
                                className={`relative appearance-none bg-transparent w-full pr-8 pt-4 pb-2 border-b focus:outline-none z-1 ${
                                  isSubmitClicked || registerForm.buildingName.isTouched
                                    ? registerForm.buildingName.value === '' ||
                                      registerForm.buildingName.value.length > 30
                                      ? 'border-error'
                                      : 'border-valid'
                                    : 'border-grey-light'
                                }`}
                                data-val="true"
                                data-val-required="Required"
                                id="BuildingName"
                                name="buildingname"
                                type="text"
                                value={registerForm.buildingName.value}
                                onChange={(e) =>
                                  setRegisterForm({
                                    ...registerForm,
                                    buildingName: {
                                      ...registerForm.buildingName,
                                      value: e.target.value,
                                    },
                                  })
                                }
                                onBlur={() =>
                                  setRegisterForm({
                                    ...registerForm,
                                    buildingName: { ...registerForm.buildingName, isTouched: true },
                                  })
                                }
                              />
                              <div className="messages">
                                <div
                                  className={`form-verification ${
                                    isSubmitClicked || registerForm.buildingName.isTouched
                                      ? registerForm.buildingName.value === ''
                                        ? ''
                                        : 'hidden'
                                      : 'hidden'
                                  }`}
                                >
                                  <p className="text-xs text-red mt-1">Building name required.</p>
                                </div>
                                <div
                                  className={`form-verification ${
                                    registerForm.buildingName.value !== '' &&
                                    registerForm.buildingName.isTouched
                                      ? registerForm.buildingName.value.length > 30
                                        ? ''
                                        : 'hidden'
                                      : 'hidden'
                                  }`}
                                >
                                  <p className="text-xs text-red mt-1">{lengthMaxMessage}</p>
                                </div>
                              </div>
                            </div>
                            {/* Street */}
                            <div className="name mb-6 md:mb-0 relative mt-4 w-full lg:w-1/2">
                              <p
                                className={`absolute left-0 top-0 z-0 duration-300 ${
                                  registerForm.street.value !== ''
                                    ? '-top-4 text-sm text-grey-light'
                                    : ''
                                }`}
                              >
                                Street *
                              </p>
                              <input
                                autoCapitalize="sentences"
                                autoComplete="off"
                                autoCorrect="off"
                                className={`relative appearance-none bg-transparent w-full pr-8 pt-4 pb-2 border-b focus:outline-none z-1 ${
                                  isSubmitClicked || registerForm.street.isTouched
                                    ? registerForm.street.value === '' ||
                                      registerForm.street.value.length > 30
                                      ? 'border-error'
                                      : 'border-valid'
                                    : 'border-grey-light'
                                }`}
                                data-val="true"
                                data-val-required="Required"
                                id="Street"
                                name="street"
                                type="text"
                                value={registerForm.street.value}
                                onChange={(e) =>
                                  setRegisterForm({
                                    ...registerForm,
                                    street: {
                                      ...registerForm.street,
                                      value: e.target.value,
                                    },
                                  })
                                }
                                onBlur={() =>
                                  setRegisterForm({
                                    ...registerForm,
                                    street: { ...registerForm.street, isTouched: true },
                                  })
                                }
                              />
                              <div className="messages">
                                <div
                                  className={`form-verification ${
                                    isSubmitClicked || registerForm.street.isTouched
                                      ? registerForm.street.value === ''
                                        ? ''
                                        : 'hidden'
                                      : 'hidden'
                                  }`}
                                >
                                  <p className="text-xs text-red mt-1">Street name required.</p>
                                </div>
                                <div
                                  className={`form-verification ${
                                    registerForm.street.value !== '' &&
                                    registerForm.street.isTouched
                                      ? registerForm.street.value.length > 30
                                        ? ''
                                        : 'hidden'
                                      : 'hidden'
                                  }`}
                                >
                                  <p className="text-xs text-red mt-1">{lengthMaxMessage}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col lg:flex-row gap-6 mb-4">
                            {/* City */}
                            <div className="name mb-6 md:mb-0 relative mt-4 w-full lg:w-1/2">
                              <p
                                className={`absolute left-0 top-0 z-0 duration-300 ${
                                  registerForm.city.value !== ''
                                    ? '-top-4 text-sm text-grey-light'
                                    : ''
                                }`}
                              >
                                City
                              </p>
                              <input
                                autoCapitalize="sentences"
                                autoComplete="off"
                                autoCorrect="off"
                                className={`relative appearance-none bg-transparent w-full pr-8 pt-4 pb-2 border-b focus:outline-none z-1 ${
                                  isSubmitClicked || registerForm.city.isTouched
                                    ? registerForm.city.value.length > 30
                                      ? 'border-error'
                                      : 'border-valid'
                                    : 'border-grey-light'
                                }`}
                                data-val="true"
                                id="City"
                                name="city"
                                type="text"
                                value={registerForm.city.value}
                                onChange={(e) =>
                                  setRegisterForm({
                                    ...registerForm,
                                    city: {
                                      ...registerForm.city,
                                      value: e.target.value,
                                    },
                                  })
                                }
                                onBlur={() =>
                                  setRegisterForm({
                                    ...registerForm,
                                    city: { ...registerForm.city, isTouched: true },
                                  })
                                }
                              />
                              <div className="messages">
                                <div
                                  className={`form-verification ${
                                    registerForm.city.value !== '' && registerForm.city.isTouched
                                      ? registerForm.city.value.length > 30
                                        ? ''
                                        : 'hidden'
                                      : 'hidden'
                                  }`}
                                >
                                  <p className="text-xs text-red mt-1">{lengthMaxMessage}</p>
                                </div>
                              </div>
                            </div>
                            {/* County */}
                            <div className="name mb-6 md:mb-0 relative mt-4 w-full lg:w-1/2">
                              <p
                                className={`absolute left-0 top-0 z-0 duration-300 ${
                                  registerForm.county.value !== ''
                                    ? '-top-4 text-sm text-grey-light'
                                    : ''
                                }`}
                              >
                                County
                              </p>
                              <input
                                autoCapitalize="sentences"
                                autoComplete="off"
                                autoCorrect="off"
                                className={`relative appearance-none bg-transparent w-full pr-8 pt-4 pb-2 border-b focus:outline-none z-1 ${
                                  isSubmitClicked || registerForm.county.isTouched
                                    ? registerForm.county.value.length > 30
                                      ? 'border-error'
                                      : 'border-valid'
                                    : 'border-grey-light'
                                }`}
                                data-val="true"
                                id="County"
                                name="county"
                                type="text"
                                value={registerForm.county.value}
                                onChange={(e) =>
                                  setRegisterForm({
                                    ...registerForm,
                                    county: {
                                      ...registerForm.county,
                                      value: e.target.value,
                                    },
                                  })
                                }
                                onBlur={() =>
                                  setRegisterForm({
                                    ...registerForm,
                                    county: { ...registerForm.county, isTouched: true },
                                  })
                                }
                              />
                              <div className="messages">
                                <div
                                  className={`form-verification ${
                                    registerForm.county.value !== '' &&
                                    registerForm.county.isTouched
                                      ? registerForm.county.value.length > 30
                                        ? ''
                                        : 'hidden'
                                      : 'hidden'
                                  }`}
                                >
                                  <p className="text-xs text-red mt-1">{lengthMaxMessage}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    ) : (
                      <div className="w-full md:w-2/3 mt-3.5">
                        <div className="flex flex-col justify-center items-center">
                          <FontAwesomeIcon icon={faSpinner} pulse className="w-12 h-12" />
                          <p className="mt-3.5 text-center">
                            One moment, retrieving address details!
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Form>
            );
          }}
        </Formik>

        {/* Heard Us */}
        <div className="mb-6 relative xs:mt-4">
          <p
            className={`absolute left-0 top-0 z-0 duration-300 ${
              registerForm.heard.value !== '' ? '-top-4 text-sm text-grey-light' : ''
            }`}
          >
            How did you hear about us?
          </p>
          <input
            type="text"
            value={selectedSource ? selectedSource.name : sourceInput}
            className={`basicInput${
              registerForm.heard.value === '' ? '' : ' active'
            } relative appearance-none bg-transparent w-full pr-8 pt-4 pb-2 border-b border-grey-lightest focus:outline-none z-1 placeholder-grey-light text-black-lighter `}
            placeholder=""
            maxLength={20}
            autoComplete="off"
            onChange={(event) => {
              setSourceInput(event.target.value);
              setRegisterForm({
                ...registerForm,
                heard: {
                  ...registerForm.heard,
                  isTouched: false,
                  value: event.target.value,
                },
              });
            }}
            onKeyDown={(event) => {
              const key = event.keyCode || event.charCode;
              if ((key == 8 || key == 46) && selectedSource) {
                setSourceInput('');
                setSelectedSource(null);
                setRegisterForm({
                  ...registerForm,
                  heard: { value: '', isTouched: false },
                });
              }
            }}
          />
          {isSourcesOpen && filteredSources.length > 0 && (
            <ul className="w-full h-auto absolute bg-white shadow-md p-2 z-1">
              {filteredSources.map((source) => {
                return (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <li
                    key={source.code}
                    onClick={() => {
                      setSelectedSource(source);
                      setIsSourcesOpen(false);
                      setRegisterForm({
                        ...registerForm,
                        heard: { value: source.code, isTouched: false },
                      });
                    }}
                    className={`cursor-pointer ${
                      companyID === Company.Ironmongery ? 'hover:bg-orange' : 'hover:bg-green'
                    } hover:bg-opacity-50`}
                  >
                    {source.name}
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        {/* Used For? */}
        <div className="form-row">
          <div className="mb-6 md:mb-0 relative xs:mt-4">
            <p
              className={`${
                registerForm.usedFor.value !== '' ? '-top-4 text-sm text-grey-light' : ''
              } duration-300`}
            >
              What will your account be used for? *
            </p>
            <div
              role="button"
              tabIndex={0}
              className={`right-0 absolute top-0 cursor-pointer z-99 mb-3`}
              onClick={() => setHover('usedFor')}
              onMouseEnter={() => setHover('usedFor')}
              onMouseLeave={() => setHover('')}
            >
              <svg
                xmlns="//www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                {' '}
                <path
                  d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.905 6.52 11.0007 6.28267 11 6C10.9993 5.71733 10.9033 5.48 10.712 5.288C10.5207 5.096 10.2833 5 10 5C9.71667 5 9.47933 5.096 9.288 5.288C9.09667 5.48 9.00067 5.71733 9 6C8.99933 6.28267 9.09533 6.52033 9.288 6.713C9.48067 6.90567 9.718 7.00133 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88334 18.6867 3.825 17.9743 2.925 17.075C2.025 16.1757 1.31267 15.1173 0.788001 13.9C0.263335 12.6827 0.000667933 11.3827 1.26582e-06 10C-0.000665401 8.61733 0.262001 7.31733 0.788001 6.1C1.314 4.88267 2.02633 3.82433 2.925 2.925C3.82367 2.02567 4.882 1.31333 6.1 0.788C7.318 0.262667 8.618 0 10 0C11.382 0 12.682 0.262667 13.9 0.788C15.118 1.31333 16.1763 2.02567 17.075 2.925C17.9737 3.82433 18.6863 4.88267 19.213 6.1C19.7397 7.31733 20.002 8.61733 20 10C19.998 11.3827 19.7353 12.6827 19.212 13.9C18.6887 15.1173 17.9763 16.1757 17.075 17.075C16.1737 17.9743 15.1153 18.687 13.9 19.213C12.6847 19.739 11.3847 20.0013 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                  fill="#777777"
                />
              </svg>
              {hover === 'usedFor' && (
                <div className="p-3 bg-white-darker absolute bottom-180% -right-50% md:-right-200% shadow-lg w-310px rounded-md z-99 border border-white-darkest">
                  <div className="font-bold sm:text-sm text-base mb-3">
                    What will your account be used for?
                  </div>
                  <p className="sm:text-sm text-base mb-5">
                    <b>Business Account:</b> Designed for professional or trade use, tailored to
                    meet the needs of businesses and organisations.
                  </p>
                  <p className="sm:text-sm text-base">
                    <b>Personal Account:</b> Perfect for individual customers or DIY projects,
                    offering a straightforward and personalised shopping experience.
                  </p>
                  <img
                    src="https://d1mgcpums0qvsa.cloudfront.net/IRM/6-0/baloon-arrow.svg"
                    alt="pointer"
                    className="absolute h-4 w-4 z-1 top-98% right-3% md:right-14%"
                  />
                </div>
              )}
            </div>
            <div className="flex mt-4 mb-1">
              <button
                className={`flex items-center form-btn focus:outline-none border-1 py-10px px-4 rounded-lg text-sm mr-4 w-1/2 xs:w-auto ${
                  registerForm.usedFor.value === Number('111') ||
                  registerForm.usedFor.value === Number('113')
                    ? 'checked border-2'
                    : ''
                }`}
                onClick={() => {
                  setRegisterForm({
                    ...registerForm,
                    usedFor: {
                      ...registerForm.usedFor,
                      value: companyID === 1 ? Number('111') : Number('113'),
                    },
                  });
                }}
              >
                <input
                  type="radio"
                  checked={
                    registerForm.usedFor.value === Number('111') ||
                    registerForm.usedFor.value === Number('113')
                  }
                  className="mr-3 h-5 w-5"
                />
                Business
              </button>
              <button
                className={`flex items-center form-btn mr-0 focus:outline-none border-1 py-10px px-4 rounded-lg text-sm w-1/2 xs:w-auto ${
                  registerForm.usedFor.value === Number('112') ||
                  registerForm.usedFor.value === Number('114')
                    ? 'checked border-2'
                    : ''
                }`}
                onClick={() => {
                  setRegisterForm({
                    ...registerForm,
                    usedFor: {
                      ...registerForm.usedFor,
                      value: companyID === 1 ? Number('112') : Number('114'),
                      companyName: '',
                    },
                  });
                }}
              >
                <input
                  type="radio"
                  checked={
                    registerForm.usedFor.value === Number('112') ||
                    registerForm.usedFor.value === Number('114')
                  }
                  className="mr-3 h-5 w-5"
                />
                Personal
              </button>
            </div>
          </div>
          <div className="messages">
            <div
              className={`form-verification ${
                isSubmitClicked ? (registerForm.usedFor.value === '' ? '' : 'hidden') : 'hidden'
              }`}
            >
              <p className="text-xs text-red mt-1">Please select your account type.</p>
            </div>
          </div>
        </div>

        {/* Company Name */}
        <div
          className={`form-row ${
            registerForm.usedFor.value === Number('111') ||
            registerForm.usedFor.value === Number('113')
              ? 'mb-1'
              : 'hidden'
          }`}
        >
          <div className="md:mb-0 relative mt-6">
            <p
              className={`absolute left-0 top-0 z-0 duration-300 ${
                registerForm.usedFor.companyName !== '' ? '-top-4 text-sm text-grey-light' : ''
              }`}
            >
              Company Name *
            </p>
            <input
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              className={`relative appearance-none bg-transparent w-full pr-8 pt-4 pb-2 border-b focus:outline-none z-1 ${
                isSubmitClicked && registerForm.usedFor.companyName === '' ? 'invalid' : 'valid'
              }`}
              data-val-required="Required"
              id="CompanyName"
              name="CompanyName"
              type="CompanyName"
              value={registerForm.usedFor.companyName}
              onChange={(e) =>
                setRegisterForm({
                  ...registerForm,
                  usedFor: { ...registerForm.usedFor, companyName: e.target.value },
                })
              }
            />
          </div>
          <div className="messages">
            <div
              className={`form-verification ${
                isSubmitClicked
                  ? (registerForm.usedFor.value === 111 || registerForm.usedFor.value === 113) &&
                    registerForm.usedFor.companyName === ''
                    ? ''
                    : 'hidden'
                  : 'hidden'
              }`}
            >
              <p className="text-xs text-red mt-1">Company name required.</p>
            </div>
          </div>
        </div>

        <div id="turnstile-container" className="mb-4 mt-8"></div>

        {/* Create Account */}
        {isSubmitValid && (
          <p className="text-xs text-red mt-3">
            Some information is missing or invalid. Please correct any of the highlighted fields
            above to create your account.
          </p>
        )}
        <button
          type="button"
          className="text-sm h-10 text-center items-center justify-center inline-flex rounded-full py-3 px-4 bg-orange hover:bg-orange-dark focus:outline-none mb-4 mt-3"
          onClick={() => handleOnSubmit()}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <PulseLoader sizeUnit="px" size={8} color="#FFFFFF" loading={isSubmitting} />
          ) : (
            'Create your Account'
          )}
        </button>

        {turnstileError && (
          <p className="text-xs text-red mb-3">
            Unable to verify captcha. Please refresh the page and try again. If the issue persists,
            please call our team on 0808 168 2828.
          </p>
        )}

        {/* Tickbox */}
        <div className="form-row mb-4">
          <div className="details">
            <p className="form-label flex">
              <button
                id={registerForm.notify ? 'tradextra-notify' : ''}
                className={`bg-concrete rounded-3px w-20px h-20px p-0 border-2 border-solid border-transparent ${
                  registerForm.notify ? 'checked' : ''
                } focus:outline-none`}
                onClick={() => setRegisterForm({ ...registerForm, notify: !registerForm.notify })}
              ></button>
              <span className="ml-10px w-full text-xs">
                Tick this box if you&apos;d like to receive exclusive discounts, product updates,
                and new launches. We&apos;ll contact you in line with our Privacy Policy, never sell
                your data, and promise to let you opt-out at any time.
              </span>
            </p>
          </div>
        </div>

        {/* Terms and Conditions */}
        <p className="text-12px">
          Terms &amp; Conditions: By creating an account you agree to the website{' '}
          <Link href="/information/terms">
            <a className="text-black-pitch underline" target="_blank" rel="noopener noreferrer">
              terms and conditions
            </a>
          </Link>{' '}
          and our{' '}
          <Link href="/information/privacy">
            <a className="text-black-pitch underline" target="_blank" rel="noopener noreferrer">
              privacy policy
            </a>
          </Link>
        </p>
      </div>
    </>
  );
};

export default AccountSignup;
