import Company from 'types/company';
import { useEffect, useState } from 'react';
import PageWrapper from '@components/pageWrapper';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import axios from 'axios';
import InfiniteLoader from '@components/infiniteLoader';
import { useRouter } from 'next/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import Link from 'next/link';
import { useDispatch } from 'react-redux';
import { setAccountDetails, setCardCode } from 'src/redux/userSlice';
import NavFooterAccount from '@components/NavFooterAccount';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import getCookies from '@utils/getCookies';
import dateToMMDDYYYY from '@utils/dateToMMDDYYYY';
import { checkoutLink } from '@utils/checkoutLink';
import { getBasket, mergeGuestItemsToBasket } from 'src/redux/basketSlice';
import SignInModal from '@components/TradeAccount/components/ID/login';

function LogIn(): JSX.Element {
  const companyID = Number(process.env.NEXT_PUBLIC_COMPANY_ID);
  const baseUrl = process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL;
  const apiKey = process.env.NEXT_PUBLIC_MANUTAN_CHECKOUT_API_KEY;
  const router = useRouter();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const [loginForm, setLoginForm] = useState({
    emailAddress: '',
    password: '',
  });
  const [forgotForm, setForgotForm] = useState({
    emailAddress: '',
  });
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoginClicked, setIsLoginClicked] = useState<boolean>(false);
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false);
  const [isLoginError, setIsLoginError] = useState<boolean>(false);
  const emailExpression = /\S+@\S+\.\S+/;

  const [redirectUrl, setRedirectUrl] = useState<string>('/');
  const environment = process.env.NEXT_PUBLIC_APP_ENV;

  const { cardCode, details } = useAppSelector((state) => state.user);
  const { basketId } = useAppSelector((state) => state.basket);

  const [redirectToCheckout, setRedirectToCheckout] = useState<boolean>(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const redirectPath = router.query.next as string;
    if (redirectPath) setRedirectUrl(`/${redirectPath}`);
  }, [router]);

  const handleIncreasingly = (element: number): void => {
    const LoginWrapper = document.querySelectorAll<HTMLElement>('.login-form__login--wrapper')[0];
    const ForgotWrapper = document.querySelectorAll<HTMLElement>('.login-form__login--wrapper')[1];

    if (element === 0) {
      ForgotWrapper.classList.add('opacity-0');
      setTimeout(function () {
        ForgotWrapper.classList.add('hidden');
        LoginWrapper.classList.remove('hidden');

        setTimeout(function () {
          LoginWrapper.classList.remove('opacity-0');
        }, 20);
      }, 20);
    } else {
      LoginWrapper.classList.add('opacity-0');
      setTimeout(function () {
        LoginWrapper.classList.add('hidden');
        ForgotWrapper.classList.remove('hidden');

        setTimeout(function () {
          ForgotWrapper.classList.remove('opacity-0');
        }, 20);
      }, 20);
    }
  };

  useEffect(() => {
    const authToken = getCookies('authToken');
    const authEmail = getCookies('authEmail');
    if (cardCode && authToken && authEmail) {
      dispatch(
        setAccountDetails({
          companyID,
          baseUrl,
          apiKey,
          email: authEmail,
          cardCode,
        })
      );

      dispatch(getBasket({ companyID, baseUrl, apiKey, token: authToken }));
    }
  }, [cardCode, companyID, baseUrl, apiKey, dispatch]);

  useEffect(() => {
    if (!redirectToCheckout) {
      return;
    }

    const authToken = getCookies('authToken');
    const authEmail = getCookies('authEmail');
    const guestBasket = localStorage.getItem('guestBasket');

    if (!authToken || !authEmail || !details || !cardCode || !basketId || !guestBasket) {
      return;
    }

    const process = async () => {
      try {
        await appDispatch(
          mergeGuestItemsToBasket({
            companyID,
            baseUrl,
            apiKey,
            basketId,
            accountCode: cardCode,
            emailAddress: authEmail,
            guestBasketItems: JSON.parse(guestBasket),
            token: authToken,
          })
        ).unwrap();
        // handle result here

        localStorage.removeItem('guestBasket');

        document.cookie = `IDLWebUser=Username=${details.emailAddress}&LastVisited=${dateToMMDDYYYY(
          new Date()
        )}&activeCompany=${
          companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
        }direct.co.uk&CardCode=${cardCode}&FirstName=${details.firstName};path=/;domain=.${
          companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
        }direct.co.uk;`;
        document.cookie = `IDLBasket=${basketId};path=/;domain=.${
          companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
        }direct.co.uk;`;

        window.location.href = checkoutLink(companyID, {
          UserName: details.emailAddress,
          LastVisited: dateToMMDDYYYY(new Date()),
          ActiveCompany: `${
            companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
          }direct.co.uk`,
          CardCode: cardCode,
          IDLBasket: basketId,
        });
      } catch (rejectedValueOrSerializedError) {
        // handle error here
      }
    };

    process();
  }, [details, cardCode, basketId, companyID, redirectToCheckout, apiKey, baseUrl, dispatch]);

  const handleToggleView = (param: boolean): void => {
    if (param) {
      handleIncreasingly(1);
    } else {
      handleIncreasingly(0);
    }
  };

  const handleClickLogin = (e): void => {
    e.preventDefault();
    setIsLoginClicked(true);
    if (
      loginForm.emailAddress !== '' &&
      emailExpression.test(loginForm.emailAddress) &&
      loginForm.password !== ''
    ) {
      setIsLoginLoading(true);
      axios({
        method: 'POST',
        url: `${baseUrl}/account/api/Auth/Login?companyID=${companyID}`,
        headers: {
          'ocp-apim-subscription-key': apiKey,
        },
        data: {
          username: loginForm.emailAddress,
          password: loginForm.password,
        },
      })
        .then((response) => {
          document.cookie = `authToken=${response.data}; max-age=86400; path=/; ${
            environment === 'production'
              ? `domain=.${
                  companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                }direct.co.uk;`
              : ''
          }`;
          document.cookie = `authEmail=${loginForm.emailAddress}; max-age=86400; path=/; ${
            environment === 'production'
              ? `domain=.${
                  companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                }direct.co.uk;`
              : ''
          }`;
          window.ScarabQueue?.push(['setEmail', loginForm.emailAddress]);
          window.ScarabQueue?.push(['go']);
          dispatch(setCardCode({ companyID, baseUrl, apiKey, token: response.data }));
          checkTTAStatus(response.data);
        })
        .catch(() => {
          document.cookie = `authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;${
            environment === 'production'
              ? `domain=.${
                  companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                }direct.co.uk;`
              : ''
          }`;
          document.cookie = `authEmail=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;${
            environment === 'production'
              ? `domain=.${
                  companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                }direct.co.uk;`
              : ''
          }`;
          setIsLoginError(true);
          setIsLoginLoading(false);
        });
    }
  };

  const checkTTAStatus = (token) => {
    if (token) {
      axios({
        method: 'GET',
        url: `${baseUrl}/account/TradeAccount/Status`,
        headers: {
          'ocp-apim-subscription-key': apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (
            res?.data?.financeApprovalStatus === true &&
            res?.data?.isCustomerAccepted === null &&
            companyID === Company.Ironmongery
          ) {
            router.push('/tradextra');
          } else {
            if (redirectUrl === '/checkout') {
              setRedirectToCheckout(true);
            } else {
              router.push(redirectUrl);
            }
          }
        })
        .catch(() => {
          if (redirectUrl === '/checkout') {
            router.push('/');
          } else {
            router.push(redirectUrl);
          }
        });
    }
  };

  const handleSendResetEmail = (): void => {
    setIsLoading(true);
    if (forgotForm.emailAddress) {
      axios({
        method: 'POST',
        url: `${baseUrl}/account/forgottenMyPassword?emailAddress=${forgotForm.emailAddress}&companyID=${companyID}`,
        headers: {
          'ocp-apim-subscription-key': apiKey,
          // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
        },
        data: {
          email: forgotForm.emailAddress,
        },
      })
        .then((res) => {
          document.body.style.overflow = 'auto';
          setMessage(res?.data?.message);
          setIsError(
            res?.data?.message ===
              'Sorry, there is no record of an account with that email address.'
              ? true
              : false
          );

          setIsLoading(false);
        })
        .catch((error) => {
          setIsError(true);
          setMessage(error?.response?.message || error?.response?.data);
          setIsLoading(false);
        });
    } else {
      setIsError(true);
      setIsLoading(false);
      setMessage('Please provide an email address.');
    }
  };

  return (
    <>
      <PageWrapper>
        <>
          <Head>
            <title>
              Login | {companyID !== Company.Ironmongery ? 'ElectricalDirect' : 'IronmongeryDirect'}
            </title>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=5.0, shrink-to-fit=no"
            />
            <link
              rel="shortcut icon"
              href={
                companyID !== Company.Ironmongery
                  ? '/images/ED-Favicon.png'
                  : '/images/ID-Favicon.png'
              }
            />
          </Head>
          {isRegisterModalOpen && (
            <SignInModal
              isRegister
              fromNav
              onClose={() => {
                setIsRegisterModalOpen(false);
                document.body.style.overflow = 'auto';
              }}
            />
          )}
          <header>
            <div className="login-header__wrapper">
              <div className="login-header__main">
                <div className="login-header__inner">
                  <Link href="/">
                    <a
                      className="login-header__logo"
                      data-tracking="logo"
                      title="IronmongeryDirect"
                      role="button"
                      tabIndex={0}
                    >
                      <img
                        src={
                          companyID === Company.Ironmongery
                            ? '/images/ID-Light-bg.svg'
                            : '/images/ED-Light-bg.svg'
                        }
                        alt={
                          companyID === Company.Ironmongery
                            ? 'IronmongeryDirect'
                            : 'ElectricalDirect'
                        }
                        width="130"
                      />
                    </a>
                  </Link>
                  <div className="login-header__basket">
                    <Link href="/basket">
                      <a id="header--minimal-basket-link" className="login-basket__anchor">
                        <svg
                          className="login-basket__svg"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="shopping-basket"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 576 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M576 216v16c0 13.255-10.745 24-24 24h-8l-26.113 182.788C514.509 462.435 494.257 480 470.37 480H105.63c-23.887 0-44.139-17.565-47.518-41.212L32 256h-8c-13.255 0-24-10.745-24-24v-16c0-13.255 10.745-24 24-24h67.341l106.78-146.821c10.395-14.292 30.407-17.453 44.701-7.058 14.293 10.395 17.453 30.408 7.058 44.701L170.477 192h235.046L326.12 82.821c-10.395-14.292-7.234-34.306 7.059-44.701 14.291-10.395 34.306-7.235 44.701 7.058L484.659 192H552c13.255 0 24 10.745 24 24zM312 392V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm112 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm-224 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24z"
                          ></path>
                        </svg>
                        {/* <div>
                        <div
                          className={`login-basket__qty${
                            companyID === Company.Ironmongery ? '--id' : '--ed'
                          }`}
                        >
                          1
                        </div>
                      </div> */}
                        <p className="login-basket__title">My Basket</p>
                        <div id="ReactHeaderPrice"></div>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="login-nav__main">
              <div className="login-nav__inner">
                <Link href="/browse/all">
                  <a className="login-nav__anchor">
                    <svg
                      className="login-nav__svg"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="chevron-left"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                      ></path>
                    </svg>{' '}
                    Continue Shopping
                  </a>
                </Link>
              </div>
            </div>
          </header>
          <div className="login-form__container">
            <div className="login-form__main">
              <div className="login-form__item">
                <form onSubmit={handleClickLogin}>
                  <div className={`login-form__login--wrapper transition-opacity duration-1000`}>
                    <h2 className="login-form__login--heading">Log In</h2>
                    <div
                      className={
                        isLoginClicked &&
                        (loginForm.emailAddress === '' ||
                          !emailExpression.test(loginForm.emailAddress) ||
                          isLoginError)
                          ? 'login-form__login--row'
                          : 'hidden'
                      }
                    >
                      <div className="login-form-row__item">
                        <div className="login-form-verification__wrapper bg-error">
                          <div className="icon">
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="times"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 352 512"
                              data-fa-i2svg=""
                            >
                              <path
                                fill="currentColor"
                                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                              ></path>
                            </svg>
                          </div>
                          <div className="description">
                            Your email address or password was not recognized. Please try again or{' '}
                            <button
                              type="button"
                              className="underline bg-transparent font-bold text-white focus:outline-none"
                              onClick={() => handleToggleView(true)}
                            >
                              reset your password
                            </button>
                            .
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="login-form__login--row">
                      <div className="login-form-row__item">
                        <p>Email Address</p>
                        <input
                          className="form-control"
                          data-val="true"
                          data-val-required="The EmailAddress field is required."
                          name="emailAddress"
                          type="email"
                          value={loginForm.emailAddress}
                          onChange={(e) =>
                            setLoginForm({ ...loginForm, emailAddress: e.target.value })
                          }
                        />
                      </div>
                      <div
                        className={
                          isLoginClicked &&
                          loginForm.emailAddress === '' &&
                          !emailExpression.test(loginForm.emailAddress)
                            ? 'login-form-row__item'
                            : 'hidden'
                        }
                      >
                        <div className="login-form-verification__wrapper bg-error">
                          <div className="icon">
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="times"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 352 512"
                              data-fa-i2svg=""
                            >
                              <path
                                fill="currentColor"
                                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                              ></path>
                            </svg>
                          </div>
                          <div className="description">Email address cannot be left blank.</div>
                        </div>
                      </div>
                    </div>
                    <div className="login-form__login--row">
                      <div className="login-form-row__item">
                        <p>Password</p>
                        <input
                          className="form-control"
                          data-val="true"
                          data-val-required="The Password field is required."
                          name="password"
                          type="password"
                          value={loginForm.password}
                          onChange={(e) => setLoginForm({ ...loginForm, password: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="login-form__login--row">
                      <div className="login-form-row__item">
                        <button
                          type="submit"
                          disabled={isLoginLoading}
                          className="w-full tracking-wider text-white font-bold text-14px h-9 text-center items-center justify-center inline-flex rounded-full py-0 px-20px uppercase bg-green-pitch hover:bg-green-darker focus:outline-none md:h-14 md:text-18px lg:h-20"
                        >
                          {isLoginLoading ? (
                            <InfiniteLoader
                              className={
                                'bg-transparent block w-full h-14 text-white text-16px leading-6'
                              }
                            />
                          ) : (
                            'Log In'
                          )}
                        </button>
                        <button
                          type="button"
                          className="block m-10px italic text-center underline w-full focus:outline-none"
                          title="Toggle Forgotten Password Box"
                          onClick={() => handleToggleView(true)}
                        >
                          {`I've forgotten my password`}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div
                  className={`login-form__login--wrapper transition-opacity duration-1000 opacity-0 hidden`}
                >
                  <h2 className="login-form__login--heading">Forgotten Password?</h2>
                  {message && (
                    <div
                      className={`flex items-center rounded-md p-0 mx-auto mb-0 w-10/12 ${
                        isError ? 'bg-error' : 'bg-green-pitch'
                      }`}
                    >
                      <div className="login-form-row__item">
                        <div className="login-form-verification__wrapper mt-0">
                          <div className="icon">
                            <FontAwesomeIcon icon={isError ? faTimes : faCheck} />
                          </div>
                          <div className="text-12px text-white font-bold py-1.5 px-20px my-2 mx-0 border-l-1 border-solid border-white">
                            {message}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="login-form__login--row">
                    <div className="login-form-row__item">
                      <p>Email Address</p>
                      <input
                        className="form-control"
                        data-val="true"
                        data-val-required="The EmailAddress field is required."
                        name="emailAddress"
                        type="email"
                        value={forgotForm.emailAddress}
                        onChange={(e) => setForgotForm({ emailAddress: e.target.value })}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleSendResetEmail();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="login-form__login--row">
                    <div className="login-form-row__item">
                      {message !==
                        'A link to reset your password will be sent to your email address within 24 hours.' && (
                        <button
                          type="button"
                          onClick={handleSendResetEmail}
                          disabled={isLoading}
                          className="w-full tracking-wider text-white font-bold text-14px h-9 text-center items-center justify-center inline-flex rounded-full py-0 px-20px uppercase bg-green-pitch hover:bg-green-darker focus:outline-none md:h-14 md:text-18px lg:h-20"
                        >
                          {isLoading ? (
                            <InfiniteLoader
                              className={
                                'bg-transparent block w-full h-14 text-white text-16px leading-6'
                              }
                            />
                          ) : (
                            'Send Reset Email'
                          )}
                        </button>
                      )}
                      <button
                        className="block m-10px italic text-center underline w-full focus:outline-none"
                        title="Toggle Forgotten Password Box"
                        onClick={() => handleToggleView(false)}
                      >
                        Return to login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="login-form__item">
                <div className="login-register__container">
                  <h1 className="login-register__heading">{`Don't have an account?`}</h1>
                  <button
                    // tabIndex={0}
                    // href={`/account/newcustomer?ls=0${
                    //   redirectUrl !== '/' ? `&next=${redirectUrl.slice(1)}` : ''
                    // }`}
                    onClick={() => setIsRegisterModalOpen(true)}
                    className="w-full max-w-full h-9 text-14px rounded-3px md:text-18px md:h-14 lg:max-w-xs mx-auto py-0 px-20px text-white inline-flex font-bold leading-none uppercase self-start items-center justify-center no-underline bg-blue-link hover:bg-blue-darker"
                  >
                    Register Today
                  </button>
                  <div className="flex flex-col border-3 border-blue-login rounded-lg p-7 mt-7 max-w-20rem space-y-4">
                    <div className="flex flex-row text-center items-center space-x-4">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="//www.w3.org/2000/svg"
                      >
                        <circle cx="20" cy="20" r="20" fill="#F1F1F1"></circle>
                        <path
                          d="M26.5 10H11.6C10.7163 10 10 10.7163 10 11.6V28.4C10 29.2837 10.7163 30 11.6 30H28.4C29.2837 30 30 29.2837 30 28.4V18"
                          stroke="#142F90"
                        ></path>
                        <path
                          d="M19.6286 25.2367L13.984 19.3229C13.9277 19.2638 13.8799 19.1971 13.8528 19.12C13.7661 18.8739 13.6283 18.2757 14.1038 17.8002C14.5909 17.3131 15.3729 17.6203 15.6451 17.7503C15.7106 17.7816 15.7682 17.8262 15.8195 17.8776L19.1201 21.1847C19.4788 21.5441 20.0752 21.4959 20.3754 21.0864C21.4212 19.66 23.7149 16.6164 25.6036 14.7071C27.4397 12.8511 30.5379 10.4042 31.1229 9.9461C31.1875 9.89544 31.2581 9.85585 31.3362 9.83033C31.7109 9.70792 32.8899 9.37699 33.3936 9.87051C33.857 10.3245 33.7559 10.8377 33.7559 11.4827C33.7559 11.8409 28.9389 15.1198 26.3283 17.9315C24.3335 20.0798 21.9825 23.5174 20.9209 25.1187C20.6272 25.5617 19.9956 25.6212 19.6286 25.2367Z"
                          fill="#142F90"
                        ></path>
                      </svg>
                      <span className="font-bold text-base">Fast checkout</span>
                    </div>
                    <div className="flex flex-row text-center items-center space-x-4">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="//www.w3.org/2000/svg"
                      >
                        <circle cx="20" cy="20" r="20" fill="#F1F1F1"></circle>
                        <path
                          d="M26.5 10H11.6C10.7163 10 10 10.7163 10 11.6V28.4C10 29.2837 10.7163 30 11.6 30H28.4C29.2837 30 30 29.2837 30 28.4V18"
                          stroke="#142F90"
                        ></path>
                        <path
                          d="M19.6286 25.2367L13.984 19.3229C13.9277 19.2638 13.8799 19.1971 13.8528 19.12C13.7661 18.8739 13.6283 18.2757 14.1038 17.8002C14.5909 17.3131 15.3729 17.6203 15.6451 17.7503C15.7106 17.7816 15.7682 17.8262 15.8195 17.8776L19.1201 21.1847C19.4788 21.5441 20.0752 21.4959 20.3754 21.0864C21.4212 19.66 23.7149 16.6164 25.6036 14.7071C27.4397 12.8511 30.5379 10.4042 31.1229 9.9461C31.1875 9.89544 31.2581 9.85585 31.3362 9.83033C31.7109 9.70792 32.8899 9.37699 33.3936 9.87051C33.857 10.3245 33.7559 10.8377 33.7559 11.4827C33.7559 11.8409 28.9389 15.1198 26.3283 17.9315C24.3335 20.0798 21.9825 23.5174 20.9209 25.1187C20.6272 25.5617 19.9956 25.6212 19.6286 25.2367Z"
                          fill="#142F90"
                        ></path>
                      </svg>
                      <span className="font-bold text-base">Order tracking</span>
                    </div>
                    <div className="flex flex-row text-center items-center space-x-4">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="//www.w3.org/2000/svg"
                      >
                        <circle cx="20" cy="20" r="20" fill="#F1F1F1"></circle>
                        <path
                          d="M26.5 10H11.6C10.7163 10 10 10.7163 10 11.6V28.4C10 29.2837 10.7163 30 11.6 30H28.4C29.2837 30 30 29.2837 30 28.4V18"
                          stroke="#142F90"
                        ></path>
                        <path
                          d="M19.6286 25.2367L13.984 19.3229C13.9277 19.2638 13.8799 19.1971 13.8528 19.12C13.7661 18.8739 13.6283 18.2757 14.1038 17.8002C14.5909 17.3131 15.3729 17.6203 15.6451 17.7503C15.7106 17.7816 15.7682 17.8262 15.8195 17.8776L19.1201 21.1847C19.4788 21.5441 20.0752 21.4959 20.3754 21.0864C21.4212 19.66 23.7149 16.6164 25.6036 14.7071C27.4397 12.8511 30.5379 10.4042 31.1229 9.9461C31.1875 9.89544 31.2581 9.85585 31.3362 9.83033C31.7109 9.70792 32.8899 9.37699 33.3936 9.87051C33.857 10.3245 33.7559 10.8377 33.7559 11.4827C33.7559 11.8409 28.9389 15.1198 26.3283 17.9315C24.3335 20.0798 21.9825 23.5174 20.9209 25.1187C20.6272 25.5617 19.9956 25.6212 19.6286 25.2367Z"
                          fill="#142F90"
                        ></path>
                      </svg>
                      <span className="font-bold text-base">5 year minimum guarantee</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </PageWrapper>
      <footer className={companyID === Company.Ironmongery ? 'theme-id' : 'theme-ed'}>
        <NavFooterAccount companyID={companyID} />
      </footer>
      <footer
        className={`bg-blackBG pt-6 pb-5 px-0 text-grey-dark w-full flex items-center text-center ${
          companyID !== Company.Ironmongery ? 'theme-ed' : 'theme-id'
        }`}
      >
        <PageWrapper>
          <>
            <p className="text-14px">
              ©{' '}
              {`${new Date().getFullYear()} ${
                companyID !== 1 ? 'ElectricalDirect' : 'IronmongeryDirect'
              }`}{' '}
              Limited. All Rights reserved. E&amp;OE.{' '}
              <Link href="/information/terms">
                <a
                  className="text-white hover:text-current hover:underline"
                  title="See our Terms &amp; Conditions"
                >
                  Terms &amp; Conditions{' '}
                </a>
              </Link>
              apply. See our{' '}
              <Link href="/information/privacy">
                <a
                  className="text-white hover:text-current hover:underline"
                  title="See our Privacy Policy"
                >
                  {' '}
                  Privacy Policy
                </a>
              </Link>
              .
            </p>
          </>
        </PageWrapper>
      </footer>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { req } = context;
  const { cookies } = req;
  const { authToken } = cookies;
  const userHasCookies = authToken !== undefined ? true : false;

  if (userHasCookies) {
    return {
      redirect: {
        destination: '/',
        permanent: true,
      },
    };
  }

  return {
    props: {},
  };
};

export default LogIn;
